import { Link, Outlet } from 'react-router-dom'
import TCFooter from './TCFooter'
import logo from './stockpiler_logo.png'

export default function AuthLayout() {
    return (
        <>
        <div class="topheader">
        <table width="100%">
            <tr>
            <td align="left"><Link className="bg-transparent" to={"/"}><img alt="logo" src={logo} width={200} /></Link></td>
            </tr>
        </table>
        </div>

        <main className='main-content'>
            <Outlet />
        </main>

        <TCFooter />
        </>
    )
}