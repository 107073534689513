import { Link, Outlet } from 'react-router-dom'
import Navigation from './Navigation'
import logo from './stockpiler_logo.png'
import Dropdown from 'react-bootstrap/Dropdown'
import TCFooter from './TCFooter'
import HelpIcon from './images/help.png'
import EditableNumber from './EditableNumber'
import UserAgreementInterstitial from './UserAgreementInterstitial'

export default function MainLayout({ userId, activePlan, role, activePlanName, onSubscriptionDropdownSelected, subscriptions, agreedToTerms, onAgreementSigned, mobileView,
  handleShowHideClicked, onAccountSelected, activeAccountName, accounts, accountValue, onAccountValueUpdated
}) {
  return (
    <div>
      <Navigation planId={activePlan} role={role} planName={activePlanName} userId={userId} outerContainerId={'outer-container'} pageWrapId={'page-wrap'} />

      <div className="topheader">
        <table width="100%">
          <tbody>
            <tr>
              <td align="left"><Link className="bg-transparent" to={"https://www.stockpiler.net"}><img alt="logo" src={logo} width={200} /></Link></td>
              <td align="left">
                <div>
                  <Dropdown width="150" onSelect={(e) => onSubscriptionDropdownSelected(e)}>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      {activePlanName}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {subscriptions.map(subscription => {
                        return (<Dropdown.Item as='planId' key={subscription.planId} eventKey={subscription.planId} value={subscription.name}>{subscription.name}</Dropdown.Item>)
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <main className='main-content'>
        {
          agreedToTerms !== 'true' ? <UserAgreementInterstitial userId={userId} onAgreementSigned={onAgreementSigned} />
            : <Outlet />
        }
      </main>

      <br></br>
      <TCFooter></TCFooter>
      <br></br>
      <br></br>
      <br></br>

      <div className='help-button'>
        <Link to="/help">
          <img style={{ marginRight: "15px" }} alt="Help" src={HelpIcon} width="30px" height="30px"></img>
        </Link>
      </div>

      <div className="footer">
        <table width="100%">
          <tbody>
            <tr>
              <td width="10%">
                <div style={{ marginLeft: "15px" }} className="sameline">
                  <input type="checkbox" checked={mobileView} onChange={(e) => handleShowHideClicked()}></input>&nbsp;Mobile
                </div>
              </td>
              <td width="45%" align="center" valign='top'>
                <div className="sameline">
                  <div>
                    <Dropdown width="150" onSelect={(e) => onAccountSelected(e)}>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        {activeAccountName}
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="analytics_hidden" >
                        <Dropdown.Item as='selectAccount' eventKey={-1} value={-1}>Select Account...</Dropdown.Item>
                        {accounts.map(account => {
                          return (<Dropdown.Item as='accountId' eventKey={account.idaccounts} value={account.idaccounts}>{account.name}</Dropdown.Item>)
                        })}
                        <Dropdown.Item as='addAccount' eventKey={-2} value={-2}><Link to="/addAccount">Manage Accounts</Link></Dropdown.Item>
                        <Dropdown.Item as='summary' eventKey={-3} value={-2}><Link to="/accountsSummary">Accounts Summary</Link></Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div >
                    Account Value: <EditableNumber value={accountValue} onValueUpdated={onAccountValueUpdated} length="8" />
                  </div>
                </div>
              </td>
              <td align="right" className='help-button'>
                <Link to="/help">
                  <img style={{ marginRight: "15px" }} alt="Help" src={HelpIcon} width="30px" height="30px"></img>
                </Link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}