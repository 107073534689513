import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";
import DatePicker from "react-datepicker";
import RichTable from './components/richTable/RichTable.jsx'
import { createDateSorter } from './components/richTable/sorters.js'
import tableHelpers from './TableHelpers.js'
import { Link } from 'react-router-dom'


function PlanStatusHistory(props) {
    const [data, setData] = useState([]);
    const [uniqueDates, setUniqueDates] = useState([]);
    const [uniqueSymbols, setUniqueSymbols] = useState([]);
    //const [dailyBalances, setDailyBalances] = useState({})
    //const [updates, setUpdates] = useState(0);
    const [chartDataBuffer, setChartDataBuffer] = useState([])
    const [startDate, setStartDate] = useState(new Date('2023-11-13'))
    const [stats, setStats] = useState({})
    const [showData, setShowData] = useState(false)

    function extractUniqueValues(values) {
        let tempDates = Object.keys(values);
        let tempSymbols = {};
        for (const date of tempDates) {
            const item = values[date];
            for (let i = 0; i < item.length; i++) {
                tempSymbols[item[i].trade_symbol] = 1
            }
        }
        setUniqueDates(tempDates);
        setUniqueSymbols(Object.keys(tempSymbols).filter(s => s !== "TOTAL").filter(s => s !== "CUMULATIVE"));
    }
    /*
        function extractDailyBalances(dates, balances) {
            let result = {};
            dates.forEach(dat => {
                const searchDate = new Date(dat);
                let balance = 0;
                let foundDate = null;
                if (balances != null) {
                    balances.forEach(d => {
                        const thisDate = new Date(d.date);
                        if (thisDate.getTime() <= searchDate.getTime()) {
                            if (foundDate === null) {
                                balance = d.balance;
                                foundDate = new Date(thisDate);
                            } else {
                                if (thisDate.getTime() >= foundDate.getTime()) {
                                    balance = d.balance;
                                    foundDate = new Date(thisDate);
                                }
                            }
                        }
                    })
                    result[dat] = balance;
                }
            })
            setDailyBalances(result);
        }
        */

    function getStats(dailyValues) {
        let stats = { count: 0, winners: 0, winningPct: 0.0, losers: 0, avgDuration: 0.0, avgProfit: 0.0, avgWin: 0.0, avgLoss: 0.0, maxWin: 0.0, maxLoss: 0.0, netProfit: 0.0, currentDrawdown: 0.0, maxProfit: 0.0 }
        let days = Object.keys(dailyValues);
        stats.count = days.length;
        days.forEach(d => {
            let day = dailyValues[d];
            let total = day ? day.find(s => s.trade_symbol === "TOTAL") : null;
            let cumulative = day ? day.find(s => s.trade_symbol === "CUMULATIVE") : null;
            stats.avgProfit = ((stats.avgProfit * stats.count) + total.change_percent) / (stats.count + 1);
            if (total && total.change_percent > 0) {
                ++stats.winners;
                stats.avgWin = ((stats.avgWin * stats.winners) + total.change_percent) / (stats.winners + 1);
                if (total.change_percent > stats.maxWin) stats.maxWin = total.change_percent;
            } else {
                ++stats.losers;
                stats.avgLoss = ((stats.avgLoss * stats.losers) + total.change_percent) / (stats.losers + 1);
                if (total.change_percent < stats.maxLoss) stats.maxLoss = total.change_percent;
            }

            if (cumulative && cumulative.change_percent > stats.maxProfit) stats.maxProfit = cumulative.change_percent;
        });

        stats.winningPct = stats.winners / (stats.winners + stats.losers) * 100;

        if (days.length) {
            let dailyTotal = dailyValues[days[0]].find(r => r.trade_symbol === "CUMULATIVE");
            stats.netProfit = dailyTotal != null ? dailyTotal.change_percent : 0;
            stats.currentDrawdown = dailyTotal ? stats.maxProfit - dailyTotal.change_percent : 0;
        }

        return stats;
    }

    /*
    function getCurrentPL(dailyValues) {
        let days = Object.keys(dailyValues);
        let value = 0;
        if (days && days.length > 0) {
            let dailyTotal = dailyValues[days[0]].find(r => r.trade_symbol === "CUMULATIVE");
            value = dailyTotal != null ? dailyTotal.change_percent : 0;
        }
        return value;
    }
    */

    useEffect(() => {
        (async function () {
            const query = `/api/planHistory?planId=` + props.planId + "&startDate=" + startDate.toLocaleDateString();
            const text = await (await fetch(query)).json();
            setData(text);
            extractUniqueValues(text);
            setChartDataBuffer(createChartData(text));
            setStats(getStats(text));
            /*
            const balances = await (await fetch(`/api/dailyBalances?userId=` + props.userId + `&planId=` + props.planId)).json();
            const dates = Object.keys(text);
            extractDailyBalances(dates, balances);
            */
        })();
    }, [props.planId, props.userId, startDate]);


    function createChartData(planHistory) {
        let days = Object.keys(planHistory);
        let retVal = [];
        days.forEach(day => {
            let dailyValues = planHistory[day];
            let dailyTotal = dailyValues.find(r => r.trade_symbol === "TOTAL");
            let value = dailyTotal != null ? dailyTotal.change_percent : 0;
            retVal.unshift([new Date(day), value]);
        })
        return retVal;
    }

    function getDayNet(dateString) {
        const dayValues = data[dateString];
        let record = dayValues ? dayValues.find(rec => rec.trade_symbol === "TOTAL") : null;
        return (record ? record.change_percent : 0);
    }

    function getDayCumulative(dateString) {
        const dayValues = data[dateString];
        let record = dayValues ? dayValues.find(rec => rec.trade_symbol === "CUMULATIVE") : null;
        return (record ? record.change_percent : 0);
    }

    function getChartData() {

        let retVal = []
        for (let i = 0; i < chartDataBuffer.length; i++) {
            if (i === 0) {
                let value = 1.0 + chartDataBuffer[i][1] / 100;
                retVal.push([chartDataBuffer[i][0], value, "#A0DDFD"])
            } else {
                let value = retVal[i - 1][1] * (1.0 + chartDataBuffer[i][1] / 100);
                retVal.push([chartDataBuffer[i][0], value, "#A0DDFD"])
            }
        }

        retVal.forEach(e => e[1] = (e[1] - 1.0));

        retVal.unshift(['Date', '% Profit', { role: "style" }]);
        return retVal;
    }

    function getStatsArray() {
        let ret = [];
        ret.push(stats);
        return ret;
    }

    function handleShowHideClicked() {
        setShowData(!showData);
    }

    /*
    function onDailyBalanceChange(date, value) {
        dailyBalances[date] = value;
    }

    async function saveDailyBalance(date) {
        const newValue = dailyBalances[date];
        if (newValue != null) {
            //const dateString = new Date(date).getFullYear() + "-" + (new Date(date).getMonth() + 1) + "-" + new Date(date).getDate();
            const dateString = date;
            const url = "/api/saveDailyBalance?userId=" + props.userId + "&planId=" + props.planId + "&balance=" + newValue + "&date=" + dateString;
            const text = await (await fetch(url)).json();
            console.log(text);
        }
        setUpdates(updates + 1)
    }

    function getDailyBalance(date) {
        return dailyBalances[date];
    }

    function getCurrentPL() {
        let value = 0;
        if (uniqueDates && uniqueDates.length > 0) {
            let dailyTotal = data[uniqueDates[0]].find(r => r.trade_symbol === "CUMULATIVE");
            value = dailyTotal != null ? dailyTotal.change_percent : 0;
        }
        return value;
    }
    */

    var chartOptions = {
        legend: { position: 'none' },
        chartArea: { 'width': '75%', 'height': '75%' },
        vAxis: { format: '#%' }
    }

    function getDailyArray() {

        let retVal = [];
        uniqueDates.forEach(dat => {

            let balances = {
                date: dat,
                net: getDayNet(dat),
                cumulative: getDayCumulative(dat),
            }
            uniqueSymbols.forEach(s => {
                const dayValues = data[dat];
                let record = dayValues ? dayValues.find(rec => rec.trade_symbol === s) : null;
                balances[s] = record ? record.position : 0
            })

            retVal.push(balances);
        });
        console.log(retVal);
        return retVal;
    }

    function handleDayRangeClicked(days) {
        let d = new Date();
        d.setDate(d.getDate() - days);
        setStartDate(d);
    }

    function handleMonthRangeClicked(months) {
        let d = new Date();
        d.setMonth(d.getMonth() - months);
        setStartDate(d);
    }

    function handleYtdClicked() {
        let d = new Date();
        d.setMonth(0);
        d.setDate(1);
        setStartDate(d);
    }

    function handleMtdClicked() {
        let d = new Date();
        d.setDate(1);
        setStartDate(d);
    }

    return (
        <div>
            <Chart
                chartType="AreaChart"
                data={getChartData()}
                height={"400px"}
                options={chartOptions}
                chartPackages={["corechart", "controls"]}
            />
            <br></br>
            <br></br>
            <center>
                Start Date: <DatePicker className="bg-transparent" selected={startDate} onChange={(date) => setStartDate(date)} />
                <br></br>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleMonthRangeClicked(12) }}>1 Year</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleMonthRangeClicked(6) }}>6 Months</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleMonthRangeClicked(3) }}>3 Months</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleMonthRangeClicked(2) }}>2 Months</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleMonthRangeClicked(1) }}>1 Month</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleDayRangeClicked(28) }}>4 Weeks</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleDayRangeClicked(21) }}>3 Weeks</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleDayRangeClicked(14) }}>2 Weeks</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleDayRangeClicked(7) }}>1 Week</Link>
                <br></br>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYtdClicked() }}>YTD</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleMtdClicked() }}>MTD</Link>
            </center>

            <RichTable data={getStatsArray()} className="table-striped table-hover table-condensed" mappers={[
                { title: 'Net P/L', field: 'netProfit', formatter: tableHelpers.percentFormatter },
                { title: 'Peak P/L', field: 'maxProfit', formatter: tableHelpers.percentFormatter },
                { title: 'Current Drawdown', field: 'currentDrawdown', formatter: tableHelpers.percentFormatter },
                { title: 'Up Days', field: 'winningPct', formatter: tableHelpers.percentFormatter },
                { title: 'Average Day', field: 'avgProfit', formatter: tableHelpers.percentFormatter },
                { title: 'Average Up Day', field: 'avgWin', formatter: tableHelpers.percentFormatter },
                { title: 'Average Down Day', field: 'avgLoss', formatter: tableHelpers.percentFormatter },
                { title: 'Best Day', field: 'maxWin', formatter: tableHelpers.percentFormatter },
                { title: 'Worst Day', field: 'maxLoss', formatter: tableHelpers.percentFormatter },
            ]} />

            <br></br>
            <br></br>
            <center>
                <Link id="refresh" className="menu-item" onClick={handleShowHideClicked}>Show / Hide Positions</Link>
            </center>

            <RichTable data={getDailyArray()} className="table-striped table-hover table-condensed" pageSize={10} mappers={[
                { title: 'Date', field: 'date', formatter: tableHelpers.dateFormmatter, sorters: createDateSorter('date') },
                { title: 'Net P/L', field: 'net', formatter: tableHelpers.percentFormatter, sorters: true },
                { title: 'Cumulative P/L', field: 'cumulative', formatter: tableHelpers.percentFormatter, sorters: true },
                ...uniqueSymbols.map(s => ({ title: s, field: s, formatter: tableHelpers.percentFormatter, sorters: true, hidden: !showData }))
            ]} />
        </div >
    );
}

export default PlanStatusHistory;