import React from 'react';
import { Link } from 'react-router-dom'
import JobStatus from './JobStatus';
import MarketDataStatus from './MarketDataStatus'

function ManageSystem(props) {

    function shouldAllow() {
        return (props.role === 3);
    }

    return (
        <div>
            {
                shouldAllow() ?
                    <div>
                        <h3>Links</h3>
                        <Link id="signals" className="menu-item" to={"/signals"}>Signal History</Link><br></br>
                        <Link id="manageusers" className="menu-item" to={"/manageusers"}>Manage Users</Link><br></br>
                        <Link id="allActiveTrades" className="menu-item" to={"/allActiveTrades"}>All Active Trades</Link><br></br>
                        <Link id="allCompletedTrades" className="menu-item" to={"/allCompletedTrades"}>All Closed Trades</Link><br></br>
                        <Link id="upload" className="menu-item" to={"/backtestupload"}>Upload TradingView files</Link><br></br>
                        <Link className="menu-item" to={"/createAlgo"}>Create Algo</Link><br></br>
                        <br></br>
                    </div>
                    :
                    <div class="empty-page" >Not allowed</div>
            }
            <h3>Periodic Jobs</h3>
            <JobStatus />
            <h3>Market Data</h3>
            <MarketDataStatus />

        </div>
    );
}

export default ManageSystem;