import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";
import { Button } from 'react-bootstrap'
import RichTable from './components/richTable/RichTable'
import tableHelpers from './TableHelpers.js'

function Forecast(props) {
    const [backtestResults, setBacktestResults] = useState({});
    const [balances, setBalances] = useState([]);
    const [slippageRate, setSlippageRate] = useState(0);
    const [monthlyWithdraw, setMonthlyWithdraw] = useState(0);
    const [monthlyDeposit, setMonthlyDeposit] = useState(0);
    const [simulationYears, setSimulationYears] = useState(5);
    const [startingAmount, setStartingAmount] = useState(Number(props.accountValue));
    const [showAverage, setShowAverage] = useState(true);
    const [monthlyAverageReturn, setMonthlyAverageReturn] = useState(0);

    useEffect(() => {
        (async function () {
            const url = `/api/lastBacktestResults?planId=` + props.planId;
            let text = await (await fetch(url)).json();
            setBacktestResults(text);
            let avgProfit = getPeriodResult(text, "monthly", "avgResult");
            setMonthlyAverageReturn(avgProfit);
        })();
    }, [props.planId]);

    function getPeriodResult(results, timeframe, key) {
        return (results && results.periodSummaries && results.periodSummaries[timeframe] && results.periodSummaries[timeframe][key]) ?
            results.periodSummaries[timeframe][key] : 0;
    }

    function getMonthlyValue(year, month) {
        if (backtestResults && backtestResults.periodSummaries && backtestResults.periodSummaries.profitByMonth)
            return backtestResults.periodSummaries.profitByMonth[year][month] ? backtestResults.periodSummaries.profitByMonth[year][month].toFixed(2) : "0"
        else
            return "0"
    }

    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }

    function getRandomMonthlyReturn() {
        if (backtestResults && backtestResults.periodSummaries && backtestResults.periodSummaries.profitByMonth) {
            const yearArray = Object.keys(backtestResults.periodSummaries.profitByMonth);
            let year = yearArray[getRandomInt(yearArray.length)];
            const monthArray = Object.keys(backtestResults.periodSummaries.profitByMonth[year]);
            let month = monthArray[getRandomInt(monthArray.length)];
            return getMonthlyValue(year, month);
        } else {
            return 0;
        }
    }

    function runSimulation() {
        let monthlyBalance = [];

        let year = new Date().getFullYear();
        let balance = startingAmount;
        let avgBalance = startingAmount;
        let percentSlippage = monthlyAverageReturn * (slippageRate / 100);

        for (let i = 0; i < simulationYears; i++) {
            let month = 0;
            if (i === 0) {
                month = new Date().getMonth();
            }
            while (month < 12) {

                let profit = (getRandomMonthlyReturn() - percentSlippage) / 100;
                let avgProfit = (monthlyAverageReturn - percentSlippage) / 100;

                let netChange = monthlyDeposit - monthlyWithdraw;
                netChange += (balance * profit);

                let avgChange = monthlyDeposit - monthlyWithdraw;
                avgChange += (avgBalance * avgProfit);

                balance += netChange;
                avgBalance += avgChange;

                monthlyBalance.push(
                    {
                        date: new Date(year, month, 0),
                        balance: balance,
                        profit: profit,
                        change: netChange,
                        avgBalance: avgBalance,
                        avgProfit: avgProfit,
                        avgChange: avgChange,
                    }
                )
                ++month;
            }
            year++;
        }

        return monthlyBalance;
    }
    /*
        function handleRunSimulation() {
            let balancesArray = [];
            for (let i = 0; i < 10; i++) {
                const balances = runSimulation();
                balancesArray.push(balances);
            }
            setBalances(balancesArray);
        }
    
        function getChartData() {
    
            if (balances.length === 0) return [];
    
            let ret = [];
            let months = balances[0].length;
            for (let month = 0; month < months; month++) {
                let value = [];
                let b = balances[0][month];
                let date = (b.date.getUTCMonth() + 1) + "/" + b.date.getUTCDate() + "/" + (b.date.getUTCFullYear() - 2000);
                value.push(date);
    
                for (let i = 0; i < balances.length; i++) {
                    value.push(balances[i][month].balance);
                }
                if (showAverage)
                    value.push(b.avgProfit);
                ret.push(value);
            }
    
            let header = ["Date"];
            balances.forEach(x => {
                header.push("Simulation");
            })
            if (showAverage) header.push("Average")
    
            ret.unshift(header);
        }
        */

    function handleRunSimulation() {
        const balances = runSimulation();
        setBalances(balances);
    }

    function getSummaryArray() {
        if (balances.length === 0)
            return [];

        let stats = {
            endBalance: balances[balances.length - 1].balance,
            avgBalance: balances[balances.length - 1].avgBalance,
            profit: balances[balances.length - 1].balance - balances[0].balance,
            profitPct: (balances[balances.length - 1].balance - balances[0].balance) / balances[0].balance * 100,
            avgProfit: (balances[balances.length - 1].avgBalance - balances[0].avgBalance) / balances[0].avgBalance * 100,

        }
        return [stats];
    }


    function getChartData() {

        let ret = [];
        ret = balances.map(b => {
            let date = (b.date.getUTCMonth() + 1) + "/" + b.date.getUTCDate() + "/" + (b.date.getUTCFullYear() - 2000);
            if (showAverage)
                return [date, b.balance, b.avgBalance];
            else
                return [date, b.balance];
        })
        if (showAverage)
            ret.unshift(["Date", "Simulation", "Average"]);
        else
            ret.unshift(["Date", "Simulation"]);

        return ret;
    }

    let chartData = getChartData();
    let summaryData = getSummaryArray(chartData);

    return (
        <>
            <table width="100%">
                <tr>
                    <td width="25%">
                        <br></br>
                        Monthly Return: {monthlyAverageReturn.toFixed(2)}%
                        <br></br><br></br>
                        Monthly Win Rate: {getPeriodResult(backtestResults, "monthly", "winningPercentage").toFixed(0)}%
                        <br></br><br></br>
                        Starting Amount: <input className="analytics_hidden" type="text" size="10" defaultValue={props.accountValue} onChange={(e) => setStartingAmount(Number(e.target.value))}></input>
                        <br></br><br></br>
                        Years: <input type="text" size="3" defaultValue={5} onChange={(e) => setSimulationYears(Number(e.target.value))}></input>
                        <br></br><br></br>
                        Monthly Withdraw: <input type="text" size="7" defaultValue={0} onChange={(e) => setMonthlyWithdraw(Number(e.target.value))}></input>
                        <br></br><br></br>
                        Monthly Deposit: <input type="text" size="7" defaultValue={0} onChange={(e) => setMonthlyDeposit(Number(e.target.value))}></input>
                        <br></br><br></br>
                        P/L Slippage %: <input type="text" size="3" defaultValue={0} onChange={(e) => setSlippageRate(Number(e.target.value))}></input>&nbsp;&nbsp;
                        <br></br><br></br>
                        Show Average:<input type="checkbox" defaultChecked={showAverage} onChange={(e) => setShowAverage(!showAverage)}></input>&nbsp;&nbsp;
                        <br></br>
                        <br></br>
                        {backtestResults && backtestResults.periodSummaries ?
                            <Button className="btn btn-primary" onClick={handleRunSimulation}>Simulate</Button>
                            :
                            <>Loading...</>
                        }
                    </td>

                    <td>
                        {balances && balances.length ?
                            <div>
                                <Chart
                                    chartType="AreaChart"
                                    data={chartData}
                                    width={"100%"}
                                    height={"400px"}
                                    chartPackages={["corechart", "controls"]}
                                />

                                <br></br>
                                This simulation uses actual returns from random months over the past 10 years in order to demonstrate winning and losing months.<br></br>
                                The Average is based off of the average monthly return.
                                <br></br>
                                <RichTable data={summaryData} className="table-striped table-hover table-condensed" mappers={[
                                    { title: 'Simulation', field: 'endBalance', formatter: tableHelpers.moneyFormatter },
                                    { title: 'Simulation Profit', field: 'profitPct', formatter: tableHelpers.percentFormatter },
                                    { title: 'Average', field: 'avgBalance', formatter: tableHelpers.moneyFormatter },
                                    { title: 'Average Profit', field: 'avgProfit', formatter: tableHelpers.percentFormatter }
                                ]} />
                            </div>
                            :
                            null}
                    </td>
                </tr>
            </table>
        </>
    );
}

export default Forecast;