import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import AlertEvaluationResults from './AlertEvaluationResults.js';
import AlertConfiguration from './AlertConfiguration.js';
import StockChart from './StockChart.js';
import AlertDataSample from './AlertDataSample'
import QuoteDateAnalysis from './QuoteDateAnalysis.js';
import CandleStickChart from './CandlestickChart.js';
import AlertParameterFinder from './AlertParameterFinder.js'

function ConfigureAlert(props) {

    const [data, setData] = useState({})
    const [config, setConfig] = useState({})
    const [maxConfig, setMaxConfig] = useState({})
    const [defaultConfig, setDefaultConfig] = useState({})
    const [performance, setPerformance] = useState({})
    const [performanceArray, setPerformanceArray] = useState([])
    const [result, setResult] = useState(null)
    const [updates, setUpdates] = useState(0);
    const [algoId, setAlgoId] = useState(null);
    const [algos, setAlgos] = useState([])
    const [startDate, setStartDate] = useState(new Date(2014, 0, 1));
    const [endDate, setEndDate] = useState(new Date());
    const [addRandomness, setAddRandomness] = useState(false);
    const [monteCarloCount, setMonteCarloCount] = useState(10);

    useEffect(() => {
        (async function () {

            let url = `/api/strategyAlert?alertId=` + props.alertId;
            let text = await (await fetch(url)).json();

            setData(text);
            if (text.defaultConfig) {
                Object.keys(text.defaultConfig).forEach(key => {
                    if (text.config[key] === undefined || text.config[key] === null) {
                        text.config[key] = text.defaultConfig[key];
                    }
                })
            }
            if (text.config) {
                setConfig(text.config);
            }
            if (text.defaultConfig)
                setDefaultConfig(text.defaultConfig);
            if (text.idalgos)
                setAlgoId(text.idalgos);

            let end = new Date();
            let evalUrl = `/api/strategyAlertEvaluator?alertId=` + props.alertId;
            evalUrl += "&startDate=2014-1-1&endDate=" + end.getFullYear().toString() + "-" + (end.getMonth() + 1).toString() + "-" + (end.getDate()).toString();
            let performanceText = await (await fetch(evalUrl)).json();
            setPerformance(performanceText);

            let perfArray = [];
            perfArray.push({ id: 1, ...performanceText });
            setPerformanceArray(perfArray);

            const algosText = await (await fetch(`/api/algos`)).json();
            console.log("algos", algosText);
            setAlgos(algosText);
        })();
    }, [props.alertId, updates]);

    function shouldAllowEdits() {
        if (props.role === 3) return true;
        else return false;
    }

    async function handlePauseClicked() {
        console.log("handleSubmit");
        const url = "/api/strategyAlertStatus?alertId=" + props.alertId + "&paused=" + !data.paused
        console.log(url);
        const r = await (await fetch(url, {
            method: 'POST'
        })).json();
        setResult(r);
        setUpdates(updates + 1);
    }

    async function handleSubmit() {
        console.log("handleSubmit");
        const url = "/api/strategyAlertUpdate?alertId=" + props.alertId
        console.log(url);
        const r = await (await fetch(url, {
            method: 'POST',
            body: JSON.stringify(config)
        })).json();
        setResult(r);
    }

    async function handleSyncBacktestTrades() {
        for (let i = 0; i < algos.length; i++) {
            const algo = algos[i];
            console.log(algo);
            if (algo.name === data.algo_name && algo.symbol === data.symbol) {
                const id = algo.idalgos;
                console.log("Syncing trades with algoId", id);
                const url = `/api/algoSyncWithStrategyAlerts?algoId=` + id;
                const text = await (await fetch(url, {
                    method: 'POST'
                })).json();
                setResult(text);
            }
        }
    }

    async function handleReset() {
        setUpdates(updates + 1);
    }

    async function handleEvaluate(conf = null, start = null, end = null) {
        console.log("handleEvaluate");
        let url = "/api/strategyAlertEvaluator?alertId=" + props.alertId
        let startToUse = start ? start : startDate;
        let endToUse = end ? end : endDate;
        if (startDate) url += "&startDate=" + startToUse.getFullYear().toString() + "-" + (startToUse.getMonth() + 1).toString() + "-" + (startToUse.getDate()).toString();
        if (endDate) url += "&endDate=" + endToUse.getFullYear().toString() + "-" + (endToUse.getMonth() + 1).toString() + "-" + (endToUse.getDate()).toString();
        url += "&addRandomness=" + (addRandomness ? 'true' : 'false');
        console.log(url);
        const r = await (await fetch(url, {
            method: 'POST',
            body: conf ? JSON.stringify(conf) : JSON.stringify(config)
        })).json();
        setPerformance(r);

        let perfArray = [];
        perfArray.push({ id: performanceArray.length + 1, ...r });
        performanceArray.forEach(p => {
            perfArray.push(p);
        })
        setPerformanceArray(perfArray);
    }

    async function handleMonteCarlo(conf = null, start = null, end = null) {
        console.log("handleMonteCarlo");
        let url = "/api/strategyAlertMonteCarlo?alertId=" + props.alertId + "&count=" + monteCarloCount
        let startToUse = start ? start : startDate;
        let endToUse = end ? end : endDate;
        if (startDate) url += "&startDate=" + startToUse.getFullYear().toString() + "-" + (startToUse.getMonth() + 1).toString() + "-" + (startToUse.getDate()).toString();
        if (endDate) url += "&endDate=" + endToUse.getFullYear().toString() + "-" + (endToUse.getMonth() + 1).toString() + "-" + (endToUse.getDate()).toString();
        url += "&addRandomness=" + (addRandomness ? 'true' : 'false');
        console.log(url);
        const r = await (await fetch(url, {
            method: 'POST',
            body: conf ? JSON.stringify(conf) : JSON.stringify(config)
        })).json();
        setPerformance(r);

        let perfArray = [];
        perfArray.push({ id: performanceArray.length + 1, ...r });
        performanceArray.forEach(p => {
            perfArray.push(p);
        })
        setPerformanceArray(perfArray);
    }

    async function evaluateConfig(c, arrayIndex) {
        let url = "/api/strategyAlertEvaluator?alertId=" + props.alertId
        if (startDate) url += "&startDate=" + startDate.toLocaleDateString();
        if (endDate) url += "&endDate=" + endDate.toLocaleDateString();
        url += "&addRandomness=" + (addRandomness ? 'true' : 'false');
        const r = await (await fetch(url, {
            method: 'POST',
            body: JSON.stringify(c)
        })).json();
        setPerformance(r);

        console.log("performanceArray in evaluateConfig", performanceArray);

        let tempArray = performanceArray;
        tempArray.push({ id: arrayIndex, ...r });
        setPerformanceArray(tempArray);
    }

    function handleClearResults() {
        setPerformanceArray([]);
    }

    function isInt(n) {
        return Number(n) === n && n % 1 === 0;
    }

    function onDataSampleUpdated(startDate, endDate) {
        setStartDate(startDate);
        setEndDate(endDate);
        handleEvaluate(null, startDate, endDate);
    }

    function onAddRandomnessUpdated(shouldAddRandomness) {
        setAddRandomness(shouldAddRandomness);
    }

    async function testRange(config, key, start, end, startCounter) {

        console.log("testRange", start, end, startCounter);
        let counter = 0;
        for (let j = start; j <= end; isInt(start) ? j++ : j = j + .1) {
            let tempConfig = JSON.parse(JSON.stringify(config));
            tempConfig[key] = j;
            let finalCounter = startCounter + counter;

            setTimeout(async (c) => {

                console.log("calling evaluate config", c);
                evaluateConfig(tempConfig, Math.round(c));

            }, 100 * counter, finalCounter);

            ++counter;
        }

        return counter;
    }

    async function handleEvaluateRange() {
        console.log("handleEvaluateRange");
        console.log("config", config);
        console.log("maxConfig", maxConfig);

        let keys = Object.keys(maxConfig);

        let counter = 0;

        if (keys.length === 1) {
            let key = keys[0];

            let start = Number(config[key]);
            let end = Number(maxConfig[key]);

            await testRange(config, key, start, end, counter);
        } else {
            let primaryKey = keys[0];
            let secondaryKey = keys[1];

            let start = Number(config[primaryKey]);
            let end = Number(maxConfig[primaryKey]);

            let secondaryStart = Number(config[secondaryKey]);
            let secondaryEnd = Number(maxConfig[secondaryKey]);

            for (let i = start; i < end; isInt(start) ? i++ : i = i + .1) {
                let tempConfig = JSON.parse(JSON.stringify(config));
                tempConfig[primaryKey] = i;

                await testRange(tempConfig, secondaryKey, secondaryStart, secondaryEnd, counter);
                counter += isInt(secondaryStart) ? (secondaryEnd - secondaryStart) : (secondaryEnd - secondaryStart) / .1;
            }
        }
    }

    function onAlgoClicked(algoId) {
        if (props.onAlgoSelected)
            props.onAlgoSelected(algoId);
    }

    function handleConfigUpdate(c) {
        console.log(c);
        setConfig(c);
        handleEvaluate(c);
    }

    function handleMaxConfigUpdated(c) {
        console.log(c);
        setMaxConfig(c);
    }

    function displayAlgoLinks() {
        let links = algos.filter(a => a.name === data.algo_name && a.symbol === data.symbol);
        if (links.length) {
            return links.map(a => {
                return <><Link to={"/algo"} onClick={() => onAlgoClicked(a.idalgos)}>{a.display_name} - {a.trade_symbol}</Link><br></br></>
            })
        }
    }

    function getTrades() {
        if (performance && performance.trades && performance.trades.length) {
            return performance.trades.map(t => {
                return {
                    purchase_date: t.purchaseDate,
                    purchase_price: t.purchasePrice,
                    sell_date: t.sellDate,
                    sell_price: t.sellPrice,
                    profitPercentage: t.profitPercentage
                }
            })
        } else {
            return [];
        }
    }

    return (
        <div>
            <Link to={"/strategies"}>Back to Alerts</Link>
            <table width="100%" border="2">
                <tr>
                    <td width="15%">Algo Name: {data.idtrading_strategy_alerts} - {data.algo_name}</td>
                    <td width="15%">Symbol: {data.symbol}</td>
                    <td width="15%">Strategy: {data.name}</td>
                    <td width="15%">Timeframe: {data.timeframe === 0 ? "Daily" : "Weekly"}</td>
                    <td width="15%">Last Triggered<br></br>{data.last_triggered}</td>
                    <td>
                        <>
                            <Link to={"/createAlgo"}>Create Algo</Link><br></br>
                            {displayAlgoLinks()}
                        </>
                    </td>
                </tr>
            </table>

            <table>
                <tr>
                    <td width="50%">
                        <AlertConfiguration config={config} defaultConfig={defaultConfig} onConfigUpdated={handleConfigUpdate} onMaxConfigUpdated={handleMaxConfigUpdated} showMaxConfig={true} />
                    </td>
                    <td align='left' valign='top' width="50%">
                        <StockChart symbol={data.symbol} trades={getTrades()} />
                    </td>
                </tr>
            </table>

            <AlertDataSample onDataSampleUpdated={onDataSampleUpdated} onAddRandomnessUpdated={onAddRandomnessUpdated} />

            <>
                <br></br>
                <button onClick={() => handleReset()}>Reset</button>
                &nbsp;&nbsp;&nbsp;<button onClick={() => handleEvaluate(config)}>Evaluate</button>
                &nbsp;&nbsp;&nbsp;<button onClick={handleEvaluateRange}>Evaluate Range</button>
                &nbsp;&nbsp;&nbsp;<button onClick={() => handleMonteCarlo(config)}>Monte Carlo</button>
                &nbsp;&nbsp;x&nbsp;<input type="text" size="4" defaultValue={10} onChange={(e) => setMonteCarloCount(e.target.value)} />
                &nbsp;&nbsp;&nbsp;<button onClick={handleClearResults}>Clear Results</button>
                {shouldAllowEdits() ? <>&nbsp;&nbsp;&nbsp;&nbsp;<button onClick={handleSubmit}>Save</button></> : null}
                {shouldAllowEdits() && algoId ? <>&nbsp;&nbsp;&nbsp;&nbsp;<button onClick={handleSyncBacktestTrades}>Sync Backtests</button></> : null}
                {shouldAllowEdits() ? <>&nbsp;&nbsp;&nbsp;<button onClick={handlePauseClicked}>{data.paused ? "Unpause" : "Pause"}</button></> : null}

                <>{result ? JSON.stringify(result) : null}</>
            </>
            <br></br>
            <br></br>
            <QuoteDateAnalysis symbol={data.symbol} trades={performance.trades} />

            <AlertEvaluationResults performance={performance} performanceArray={performanceArray} shouldShowTrades={!props.mobileView && Object.keys(maxConfig).length === 0} />

            <AlertParameterFinder alertId={props.alertId} config={config} />

            <br></br>
            <CandleStickChart height={600} width={800} symbol={data.symbol} trades={getTrades()} />


        </div >);
}

export default ConfigureAlert;