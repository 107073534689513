import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import RichTable from './components/richTable/RichTable.jsx'
import tableHelpers from './TableHelpers.js'

function AlgoStats(props) {
    const [data, setData] = useState([]);
    const [years, setYears] = useState(1);
    const [filtered, setFiltered] = useState(true)

    useEffect(() => {
        (function () {
            fetch(`/api/algoStats?planId=` + props.planId + "&all=" + (filtered ? "0" : "1"))
                .then(res => res.json())
                .then(text => {
                    setData(text);
                });
        })();
    }, [filtered, props.planId]);


    function onAlgoClicked(algoId) {
        console.log("onAlgoClicked", algoId)
        if (props.onAlgoSelected)
            props.onAlgoSelected(algoId);
    }

    function getSummaryArray() {
        let stats = { count: 0, winners: 0, losers: 0, winningPct: 0.0, netProfit: 0.0, avgProfit: 0.0, avgWin: 0.0, avgLoss: 0.0, maxWin: 0.0, maxLoss: 0.0, totalProfit: 0.0, kelly: 0.0 }

        data.filter(s => s.years === years).forEach(algo => {

            const netProfit = (algo.avg_profit * algo.count);
            const winners = Math.round((algo.winning_percentage / 100 * algo.count));
            const losers = algo.count - winners;
            const winnersProfit = algo.avg_win * winners;
            const losersProfit = algo.avg_loss * losers;
            const totalWinners = stats.winners + winners;
            const totalLosers = stats.losers + losers;
            console.log("losersProfit", algo.display_name, losers, losersProfit);
            if (totalWinners) stats.avgWin = ((stats.avgWin * stats.winners) + (winnersProfit)) / totalWinners;
            if (totalLosers) stats.avgLoss = ((stats.avgLoss * stats.losers) + (losersProfit)) / totalLosers;
            console.log("avgLoss", stats.avgLoss);

            stats.winners += winners;
            stats.losers += losers;
            stats.netProfit += netProfit;
            stats.count += algo.count;
        });

        stats.winningPct = (stats.winners / (stats.count) * 100);
        stats.avgProfit = stats.netProfit / stats.count;
        const probabilityOfWinning = (stats.winningPct / 100);
        const winLossRatio = Math.abs(stats.avgWin / stats.avgLoss);
        stats.kelly = (probabilityOfWinning - ((1 - probabilityOfWinning) / winLossRatio)) * 100
        return [stats];
    }

    function getStats() {
        return data.filter(s => s.years === years).map(stat => {

            const probabilityOfWinning = (stat.winning_percentage / 100);
            const winLossRatio = stat.avg_loss === 0 ? stat.avg_win : Math.abs(stat.avg_win / stat.avg_loss);
            const kelly = probabilityOfWinning === 0 ? 0 : (probabilityOfWinning - ((1 - probabilityOfWinning) / winLossRatio)) * 100

            return {
                display_name: stat.display_name,
                winning_percentage: stat.winning_percentage,
                avg_profit: stat.avg_profit,
                avg_win: stat.avg_win,
                avg_loss: stat.avg_loss,
                avg_duration: stat.avg_duration,
                max_win: stat.max_win,
                max_loss: stat.max_loss,
                trade_symbol: stat.trade_symbol,
                count: stat.count,
                algo_type: stat.algo_type,
                algoId: stat.algoId,
                percentage: stat.percentage,
                kelly: kelly,
                net_profit: stat.avg_profit * stat.count,
                annual_profit: (stat.avg_profit * stat.count) / stat.years
            }
        })
    }

    function algoLinkFormatter(cell, row) {
        let algoid = row.algoId;
        return (
            <Link className="bg-transparent" to={"/algo"} onClick={() => onAlgoClicked(algoid)}>{cell}</Link>
        )
    }

    return (
        <>
            <br></br>
            <br></br>
            <center>
                <Link id="one" className="menu-item" onClick={e => { setYears(0) }}>Live Trades</Link>&nbsp;&nbsp;
                <Link id="one" className="menu-item" onClick={e => { setYears(1) }}>1 Years</Link>&nbsp;&nbsp;
                <Link id="one" className="menu-item" onClick={e => { setYears(2) }}>2 Years</Link>&nbsp;&nbsp;
                <Link id="one" className="menu-item" onClick={e => { setYears(3) }}>3 Years</Link>&nbsp;&nbsp;
                <Link id="one" className="menu-item" onClick={e => { setYears(5) }}>5 Years</Link>&nbsp;&nbsp;
                <Link id="one" className="menu-item" onClick={e => { setYears(10) }}>10 Years</Link>&nbsp;&nbsp;
                <br></br>
                {filtered ? <Link id="filtered" className="menu-item" onClick={e => { setFiltered(false) }}>Show All</Link>
                    : <Link id="filtered" className="menu-item" onClick={e => { setFiltered(true) }}>Show Plan</Link>}
                <br></br>
                <Link id="managealgos" className="bg-transparent" to={"/managealgos"}>Update Algos</Link>
            </center>
            <br></br>
            <center>
                <h3>{years} Years</h3>
            </center>
            <RichTable data={getSummaryArray()} className="table-striped table-hover table-condensed" mappers={[
                { title: 'Count', field: 'count', formatter: tableHelpers.intFormatter, hidden: props.mobileView },
                { title: 'Win %', field: 'winningPct', formatter: tableHelpers.percentFormatter },
                { title: 'Avg Profit', field: 'avgProfit', formatter: tableHelpers.percentFormatter },
                { title: 'Avg Win', field: 'avgWin', formatter: tableHelpers.percentFormatter },
                { title: 'Avg Loss', field: 'avgLoss', formatter: tableHelpers.percentFormatter },
                { title: 'Kelly', field: 'kelly', formatter: tableHelpers.percentFormatter, hidden: props.mobileView }
            ]} />

            <br></br>
            <br></br>
            <RichTable data={getStats()} className="table-striped table-hover table-condensed" mappers={[
                { title: 'Algo', field: 'display_name', formatter: algoLinkFormatter, sorters: true },
                { title: 'Symbol', field: 'trade_symbol', sorters: true },
                { title: 'Type', field: 'algo_type', hidden: props.mobileView, sorters: true },
                { title: 'Allocation', field: 'percentage', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
                { title: 'Total Profit', field: 'net_profit', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
                { title: 'Annual Profit', field: 'annual_profit', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
                { title: 'Win %', field: 'winning_percentage', formatter: tableHelpers.percentFormatter, sorters: true },
                { title: 'Trades', field: 'count', formatter: tableHelpers.intFormatter, hidden: props.mobileView, sorters: true },
                { title: 'Avg P/L', field: 'avg_profit', formatter: tableHelpers.percentFormatter, sorters: true },
                { title: 'Kelly', field: 'kelly', formatter: tableHelpers.percentFormatter, sorters: true },
                { title: 'Avg Win', field: 'avg_win', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
                { title: 'Avg Loss', field: 'avg_loss', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
                { title: 'Max Win', field: 'max_win', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
                { title: 'Max Loss', field: 'max_loss', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
                { title: 'Duration', field: 'avg_duration', formatter: tableHelpers.intFormatter, hidden: props.mobileView, sorters: true }
            ]} />

            <br></br>
            <br></br>
        </>
    );
}

export default AlgoStats;