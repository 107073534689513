import React, { useState, useEffect } from 'react';
import tableHelpers from './TableHelpers.js'
import { Link } from 'react-router-dom'
import RichTable from './components/richTable/RichTable.jsx'
import { createDateSorter } from './components/richTable/sorters.js'

function Signals(props) {
    const [data, setData] = useState([]);
    const [todayOnly, setTodayOnly] = useState(false);
    const [algo, setAlgo] = useState('testalgo')
    const [baseSymbol, setBaseSymbol] = useState('SPY')
    const [action, setAction] = useState('buy')
    const [result, setResult] = useState('Ready...')
    const [updates, setUpdates] = useState(0);

    useEffect(() => {
        (async function () {
            const text = await (await fetch(`/api/Signals`)).json();
            console.log("signals", text);
            setData(text);
        })();
    }, [updates]);

    function getSignals() {
        let signals = data;
        if (todayOnly) signals = signals.filter(signal => {
            let date = new Date(signal.datetime);
            let today = new Date();
            return (date.getUTCFullYear() === today.getUTCFullYear() && date.getMonth() === today.getMonth() && date.getDate() === today.getDate())
        })
        return signals.map(signal => {
            return {
                datetime: signal.datetime,
                algo: signal.algo,
                symbol: signal.symbol,
                action: signal.action,
                percent: signal.percent,
                id: signal.idwebhook_history
            }
        });
    }

    function shouldAllowEdits() {
        if (props.role === 3) return true;
        else return false;
    }

    async function handleSubmit() {
        setResult('Forcing trade...');
        const url = "/api/forceTrade?algo=" + algo + "&baseSymbol=" + baseSymbol + "&action=" + action;
        console.log(url);
        const r = await (await fetch(url)).json();
        setResult(r);
        setUpdates(updates + 1);
    }

    async function handleDeleteSignal(signalId) {
        console.log("Delete signal", signalId);
        setResult('Deleting trades...');
        const url = "/api/signalDelete?signalId=" + signalId;
        const r = await (await fetch(url)).json();
        setResult(r);
        setUpdates(updates + 1);
    }

    function deleteButtonFormatter(cell, row) {
        return (
            <>
                {shouldAllowEdits() ? <button class="btn btn-danger" onClick={() => handleDeleteSignal(row.id)}>Delete</button> : null}
            </>
        )
    }

    async function handleSendEmail() {
        console.log("Sending email");
        setResult('Sending daily emails...');
        const url = "/api/dailyTradeEmails"
        const r = await (await fetch(url, { method: 'POST' })).json();
        setResult(r);
    }

    async function handlePostDiscord() {
        console.log("handlePostDiscord");
        setResult('Posting trades to Discord');
        const url = "/api/dailyTradeDiscordChannels"
        const r = await (await fetch(url, { method: 'POST' })).json();
        setResult(r);
    }

    async function handlePostTradeTweets() {
        console.log("handlePostTradeTweets");
        const url = "/api/dailyTradeTweets"
        const r = await (await fetch(url, { method: 'POST' })).json();
        setResult(r);
    }

    async function handlePostPositionsTweet() {
        console.log("handlePostPositionsTweet");
        const url = "/api/dailyPositionsTweets"
        const r = await (await fetch(url, { method: 'POST' })).json();
        setResult(r);
    }

    async function handleDailyEvaluateAllAlerts() {
        console.log("handleEvaluateAllAlerts");
        const url = "/api/dailyStrategyAlertEvaluator"
        const r = await (await fetch(url, { method: 'POST' })).json();
        setResult(r);
    }

    function handleRefreshClicked() {
        console.log("Refresh clicked");
        setUpdates(updates + 1);
    }

    const signalTableMapper = [
        { title: 'Date', field: 'datetime', formatter: tableHelpers.dateESTFormmatter, sorters: createDateSorter('datetime') },
        { title: 'Algo', field: 'algo', sorters: true },
        { title: 'Symbol', field: 'symbol', sorters: true },
        { title: 'Action', field: 'action', sorters: true },
        { title: 'Percent', field: 'percent', sorters: true, formatter: tableHelpers.intFormatter },
        { title: 'Delete', field: 'id', formatter: deleteButtonFormatter }
    ]

    return (
        <>
            <br></br>
            Show Today Only: <input type="checkbox" defaultChecked={todayOnly} onChange={(e) => setTodayOnly(!todayOnly)}></input>
            <br></br>
            <Link className="bg-transparent" onClick={handleRefreshClicked}>Refresh</Link>
            <br></br>
            <hr></hr>

            {shouldAllowEdits() ?
                <>
                    Algo: <input type="text" size="20" defaultValue="testalgo" onChange={(e) => setAlgo(e.target.value)}></input>
                    &nbsp;&nbsp;Base Symbol: <input type="text" size="6" defaultValue="SPY" onChange={(e) => setBaseSymbol(e.target.value)}></input>
                    &nbsp;&nbsp;Action: <input type="text" size="7" defaultValue="buy" onChange={(e) => setAction(e.target.value)}></input>
                    <br></br><br></br>{shouldAllowEdits() ? <button class="btn btn-secondary" onClick={(e) => handleSubmit()}>Force Trade</button> : null}
                </>
                :
                <>
                    Not allowed to force trades
                </>
            }

            <hr></hr>
            <br></br>

            {shouldAllowEdits() ? <button class="btn btn-danger" onClick={(e) => handleDailyEvaluateAllAlerts()}>Evaluate All Alerts</button> : <>Not allowed</>}
            {shouldAllowEdits() ? <>&nbsp;&nbsp;&nbsp;<button class="btn btn-secondary" onClick={(e) => handleSendEmail()}>Send Daily Email</button></> : <>Not allowed</>}
            {shouldAllowEdits() ? <>&nbsp;&nbsp;&nbsp;<button class="btn btn-secondary" onClick={(e) => handlePostDiscord()}>Post to Discord</button></> : <>Not allowed</>}
            {shouldAllowEdits() ? <>&nbsp;&nbsp;&nbsp;<button class="btn btn-secondary" onClick={(e) => handlePostTradeTweets()}>Tweet Trades</button></> : <>Not allowed</>}
            {shouldAllowEdits() ? <>&nbsp;&nbsp;&nbsp;<button class="btn btn-secondary" onClick={(e) => handlePostPositionsTweet()}>Tweet Positions</button></> : <>Not allowed</>}

            <hr></hr>
            <br></br>
            {
                result ?
                    <>
                        {JSON.stringify(result)}
                        <hr></hr >
                        <br></br>
                    </>
                    :
                    null
            }
            <RichTable pageSize={10} data={getSignals()} mappers={signalTableMapper} className="table-striped table-hover table-condensed" />
        </>
    );
}

export default Signals;