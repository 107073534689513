import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import './index.css'
import tableHelpers from './TableHelpers.js'
import RichTable from './components/richTable/RichTable.jsx'
import forceLogout from './helpers/forceLogout'

function Subscriptions(props) {
    const [data, setData] = useState([]);
    const [refreshCount, setRefreshCount] = useState(0)

    useEffect(() => {
        (async function () {
            const response = await fetch(`/api/subscriptions?userId=` + props.userId)
            if (!response.ok) {
                forceLogout()
                return
            }
            
            const text = await response.json()
            setData(text);
        })();
    }, [props.userId, refreshCount]);

    function onPlanClicked(planId, planName) {
        if (props.onPlanSelected)
            props.onPlanSelected(planId, planName);
    }

    function getPlans() {
        return data.map(plan => {
            return {
                name: plan.name,
                description: plan.description,
                planId: plan.planId,
                is_primary: plan.is_primary,
                notifications: plan.notifications,
                annual_profit_percentage: plan.annual_profit_percentage,
                weekly_trade_count: plan.annual_trade_count / 52,
                max_drawdown_percentage: plan.max_drawdown_percentage,
                average_weekly_drawdown: plan.average_weekly_drawdown,
                best_year: plan.best_year,
                worst_year: plan.worst_year,
                backtest_years: plan.backtest_years
            };
        })
    }

    function planLinkFormatter(cell, row) {
        let planId = row.planId;
        return (
            <Link className="bg-transparent" to={`/overview`} onClick={() => onPlanClicked(planId, cell)}>{cell}</Link>
        )
    }

    async function onPrimaryChecked(planId) {
        console.log("onPrimaryChecked", planId);

        let plans = data.map(p => p);
        let plan = plans.findIndex(p => p.planId === planId);
        if (plan >= 0 && plans[plan].is_primary === 0) {

            console.log("Updating primary to ", planId);
            for (let i = 0; i < plans.length; i++) {
                plans[i].is_primary = 0;
            }
            plans[plan].is_primary = !plans[plan].is_primary;
            setData(plans);

            await (await fetch(`/api/updatePrimarySubscription?userId=` + props.userId + `&planId=` + planId)).json();
            setRefreshCount(Math.random());
        } else {
            console.log("Could not find plan", plan);
        }
    }

    async function onNotificationsChecked(planId) {
        console.log("onNotificationsChecked", planId);

        let plans = data.map(p => p);
        let plan = plans.findIndex(p => p.planId === planId);
        if (plan >= 0) {
            plans[plan].notifications = !plans[plan].notifications;
            setData(plans);

            await (await fetch(`/api/updateSubscriptionNotification?userId=` + props.userId + `&planId=` + planId)).json();
            setRefreshCount(Math.random());
        } else {
            console.log("Could not find plan", plan);
        }
    }

    function primaryFormatter(cell, row) {
        return (
            <input type='checkbox' checked={row.is_primary === 1} onClick={() => onPrimaryChecked(row.planId, row.is_primary)} />
        );
    }

    function notifcationFormatter(cell, row) {
        return (
            <input type='checkbox' checked={row.notifications === 1} onClick={() => onNotificationsChecked(row.planId)} />
        );
    }

    const planMapper = [
        { title: `Name`, field: 'name', sorters: true, formatter: planLinkFormatter },
        { title: `Description`, field: 'description' },
        { title: `Primary`, field: 'is_primary', formatter: primaryFormatter },
        { title: `Notifications`, field: 'email', formatter: notifcationFormatter },
        { title: `Annual Profit`, field: 'annual_profit_percentage', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
        { title: `Trades per week`, field: 'weekly_trade_count', formatter: tableHelpers.intFormatter, hidden: props.mobileView, sorters: true },
        { title: `Max Drawdown`, field: 'max_drawdown_percentage', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
        { title: `Best Year`, field: 'best_year', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
        { title: `Worst Year`, field: 'worst_year', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
    ]

    return (
        <div>
            {
                props.userId === null || props.userId === undefined || Number(props.userId) === 2 ?
                    (
                        <div>
                            <center>
                                <br></br>
                                <br></br>
                                You currently aren't logged in.
                                <br></br>
                                <Link className="transparent-item" to={"/login"} >Login</Link> to view your plans.
                                <br></br>
                                <br></br>
                            </center>
                        </div>
                    )
                    : null
            }

            <RichTable data={getPlans()} mappers={planMapper} className="table-striped table-hover table-condensed" />

            <br></br>
            <br></br>

        </div>);
}

export default Subscriptions;