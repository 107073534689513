import React from 'react';
import { Link } from 'react-router-dom'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

function Tooltip(props) {
    return (
        <>
            <Popup
                trigger={<Link className={props.textClass ? props.textClass : 'tooltip-link'}>{props.text}</Link>}
                position={props.position ? props.position : "right center"}
                closeOnDocumentClick
                onOpen={props.onOpen}
                contentStyle={{ width: props.width ? props.width : '400px' }}
            >
                <pre>{JSON.stringify(props.json, null, '\t')}</pre>
                <pre>{props.children}</pre>
            </Popup>
        </>
    );
}

export default Tooltip;