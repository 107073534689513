import Algos from './Algos.js';
import React, { useState, useEffect } from 'react';
import AlgosChart from './AlgosChart.js';

function ManageAlgos(props) {

    const [itemsChanged, setItemsChanged] = useState(0);
    const [planMetadata, setPlanMetadata] = useState({});

    useEffect(() => {
        (async function () {
            let metadata = await (await fetch(`/api/planMetaData?planId=` + props.planId)).json();
            if (metadata && metadata.length)
                setPlanMetadata(metadata[0])
        })();
    }, [props.planId]);


    const onAddAlgoToPlan = async (algoId) => {
        console.log("onAddAlgoToPlan", algoId);

        const text = await (await fetch(`/api/addAlgoToPlan?planId=` + props.planId + `&algoId=` + algoId)).json();
        setItemsChanged(itemsChanged + 1);
        console.log(text);
    }

    const onRemoveAlgoFromPlan = async (algoId) => {
        console.log("onRemoveAlgoFromPlan", algoId);

        const text = await (await fetch(`/api/removeAlgoFromPlan?planId=` + props.planId + `&algoId=` + algoId)).json();
        setItemsChanged(itemsChanged + 1);
        console.log(text);
    }

    const onAllocationUpdated = async (algoId) => {
        //setItemsChanged(itemsChanged + 1);
    }

    function shouldAllowEdits() {
        console.log("role", props.role);
        console.log("userId", props.userId);
        console.log("planMetadata", planMetadata);
        //console.log("shouldAllowEdits", planMetadata, props.userId, props.role)
        if (props.role === 3) return true;
        if (props.userId === planMetadata.userIdOwner) return true;
        return false;
    }

    return (
        <>
            <div>
                <Algos showStats={true} onAlgoSelected={props.onAlgoSelected} showAudit={false} allowEdit={shouldAllowEdits} showAssigned={true} planId={props.planId} onAllocationUpdated={onAllocationUpdated} onAddAlgoToPlan={onAddAlgoToPlan} onRemoveAlgoFromPlan={onRemoveAlgoFromPlan} itemsChanged={itemsChanged} />
                <AlgosChart planId={props.planId} itemsChanged={itemsChanged} />
            </div>
            <div>
                <h2>All Algos</h2>
                <Algos onAlgoSelected={props.onAlgoSelected} showAudit={true} allowEdit={shouldAllowEdits} showAssigned={false} planId={props.planId} onAllocationUpdated={onAllocationUpdated} onAddAlgoToPlan={onAddAlgoToPlan} onRemoveAlgoFromPlan={onRemoveAlgoFromPlan} itemsChanged={itemsChanged} />
            </div>
        </>
    );
}

export default ManageAlgos;