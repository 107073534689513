import React from 'react';
import { Chart } from "react-google-charts";

function PositionsChart(props) {

    function getProfit(position, avgPrice) {
        let quote = position.lastClose;
        if (avgPrice > 0 && quote > 0) {
            return ((quote - avgPrice) / avgPrice * 100);
        } else {
            return 0;
        }
    }

    function getPLChartData() {
        let chartData = {};
        chartData.data = [];
        chartData.minValue = 0;
        chartData.maxValue = 0;

        props.positions.forEach(pos => {
            const profit = getProfit(pos, pos.avgPrice);
            if (pos.netPosition !== 0) {
                chartData.data.push([pos.netSymbol, profit / 100, profit >= 0 ? "#B2D8B2" : "#B85D5D"])
                if (profit / 100 > chartData.maxValue) chartData.maxValue = profit / 100;
                if (profit / 100 < chartData.minValue) chartData.minValue = profit / 100;
            }
        });
        chartData.data.sort((a, b) => {
            return a[1] - b[1];
        })

        if (chartData.data.length)
            chartData.data.unshift(['Symbol', 'P/L', { role: "style" }]);

        return chartData;
    }

    function getTodaysChangeData() {
        let chartData = {};
        chartData.data = [];
        chartData.minValue = 0;
        chartData.maxValue = 0;

        props.positions.forEach(pos => {
            const change = pos.todayChangePercent / 100;
            if (pos.netPosition !== 0) {
                chartData.data.push([pos.netSymbol, change, change >= 0 ? "#B2D8B2" : "#B85D5D"])
                if (change > chartData.maxValue) chartData.maxValue = change;
                if (change < chartData.minValue) chartData.minValue = change;
            }
        });
        chartData.data.sort((a, b) => {
            return a[1] - b[1];
        })

        if (chartData.data.length)
            chartData.data.unshift(['Symbol', 'P/L', { role: "style" }]);

        return chartData;
    }

    let chartData = getPLChartData();
    let todaysChangeChartData = getTodaysChangeData();
    let minValue = chartData.minValue;
    let maxValue = chartData.maxValue;

    var chartOptionsPL = {
        vAxis: {
            format: '#%',
            viewWindowMode: 'explicit',
            viewWindow: {
                max: maxValue + (Math.abs(maxValue) * .1),
                min: minValue - (Math.abs(minValue) * .1)
            },
            minValue: maxValue + (Math.abs(maxValue) * .1),
            maxValue: minValue - (Math.abs(minValue) * .1)
        },
        title: "P/L by Stock",
        legend: { position: 'none' },
        bar: { width: "25" },
    }

    var chartOptionsTodaysChange = {
        vAxis: {
            format: '#%',
            viewWindowMode: 'explicit',
            viewWindow: {
                max: todaysChangeChartData.maxValue + (Math.abs(todaysChangeChartData.maxValue) * .1),
                min: todaysChangeChartData.minValue - (Math.abs(todaysChangeChartData.minValue) * .1)
            },
            minValue: todaysChangeChartData.maxValue + (Math.abs(todaysChangeChartData.maxValue) * .1),
            maxValue: todaysChangeChartData.minValue - (Math.abs(todaysChangeChartData.minValue) * .1)
        },
        title: "Today's Change",
        legend: { position: 'none' },
        bar: { width: "20" },
    }

    return (
        <table width="100%">
            <tbody>
            <tr>
                <td width="50%">
                    {props.positions && props.positions.length ?
                        <Chart
                            chartType="ColumnChart"
                            data={chartData.data}
                            width={"100%"}
                            options={chartOptionsPL}
                            chartPackages={["corechart", "controls"]}
                        />
                        : null
                    }
                </td>
                <td>
                    {props.positions && props.positions.length ?
                        <Chart
                            chartType="ColumnChart"
                            data={todaysChangeChartData.data}
                            options={chartOptionsTodaysChange}
                            width={"100%"}
                            chartPackages={["corechart", "controls"]}
                        />
                        : null
                    }
                </td>
            </tr>
            </tbody>
        </table >
    );
}

export default PositionsChart;