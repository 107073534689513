import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap'
import './index.css'
import forceLogout from './helpers/forceLogout'

function ManagePlans(props) {
    const [data, setData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [updates, setUpdates] = useState(0);

    useEffect(() => {
        (async function () {
            setData([]);
            setOriginalData([]);
            console.log("Loading plans...");

            const response = await fetch(`/api/subscriptions?userId=` + props.userId)
            if (!response.ok) {
                console.error(`Failed to load subscriptions for user ${props.userId} - HTTP ${response.status}`)
                forceLogout()
                return
            }

            const text = await response.json();
            setData(text);
            setOriginalData(text);
        })();
    }, [updates, props.userId]);

    function onDescriptionUpdated(planId, description) {
        const newData = data.map((plan) => {
            if (plan.idplans === planId) {
                return { ...plan, description: description };
            }
            return plan;
        });

        setData(newData);
    }

    function onNameUpdated(planId, name) {
        const newData = data.map((plan) => {
            if (plan.idplans === planId) {
                return { ...plan, name: name };
            }
            return plan;
        });
        setData(newData);
    }
    async function handleSubmitDescription(planId) {
        console.log("handleSubmitDescription", planId);
        let plan = data.find(p => p.idplans === planId);
        if (plan) {
            const url = "/api/planUpdate?planId=" + planId + "&name=" + plan.name + "&description=" + plan.description;
            await (await fetch(url)).json();
            setUpdates(updates + 1)
            if (props.onPlansUpdated)
                props.onPlansUpdated();
        } else {
            console.log("Could not find plan to update", planId);
        }
    }
    async function handleClone(planId) {
        console.log("handleClone", planId);
        const url = `/api/clonePlan?planId=` + planId + `&userId=` + props.userId;
        await (await fetch(url)).json();
        setUpdates(updates + 1)
        if (props.onPlansUpdated)
            props.onPlansUpdated();
    }
    async function handleDelete(planId) {
        console.log("handleDelete", planId);
        const url = `/api/planDelete?planId=` + planId + `&userId=` + props.userId;
        await (await fetch(url)).json();
        setUpdates(updates + 1)
        if (props.onPlansUpdated)
            props.onPlansUpdated();
    }
    function shouldAllowEdits(planId) {
        if (props.role === 3) return true;
        let plan = data.find(p => p.idplans === planId);
        if (plan && props.userId === plan.userIdOwner) return true;
        return false;
    }
    return (
        <div>
            <Table border="1">
                <tr>
                    <th>Name</th>
                    <th>Change Name</th>
                    <th>Description</th>
                    <th>Manage</th>
                </tr>
                {originalData.map(plan =>
                    <tr key={plan.name}>
                        <td>{plan.name}</td>
                        <td><input type="text" size="20" defaultValue={plan.name} onChange={(e) => onNameUpdated(plan.idplans, e.target.value)}></input></td>
                        <td><input type="text" size="30" defaultValue={plan.description} onChange={(e) => onDescriptionUpdated(plan.idplans, e.target.value)}></input></td>
                        &nbsp;{shouldAllowEdits(plan.idplans) ? <button class="btn btn-secondary" onClick={(e) => handleSubmitDescription(plan.idplans)}>Update</button> : null}
                        <td><button class="btn btn-primary" onClick={() => handleClone(plan.planId)}>Clone</button>
                            {shouldAllowEdits(plan.idplans) ? <>&nbsp;&nbsp;&nbsp;<button class="btn btn-danger" onClick={() => handleDelete(plan.planId)}>Delete</button></> : null}</td>
                    </tr>
                )}
            </Table>
        </div>);
}

export default ManagePlans;