// TODO: can remove this after we update to React > 17
import React from 'react'

export default function Filter({ filterType, onFilter, title, data, field }) {
    if (!filterType || !onFilter || !title || !data || !field) {
        throw new Error('Filter component requires the following props: { filterType, applyFilter, title, data, field }')
    }

    let filterComponent = <></>

    switch (filterType) {
        case 'choice':
            if (!data || !field) {
                throw new Error('Choice filter requires data and field props')
            }
            filterComponent = (
                <select
                    onChange={(e) => onFilter(field, e.target.value)}
                    className="rich-table-filter-input rich-table-filter-input-option"
                >
                    <option value="">Select {title}...</option>
                    {[...new Set(data.map((row) => row[field]))].filter((value) => value).map((value) => (
                        <option key={value} value={value}>{value}</option>
                    ))}
                </select>
            )
            break
        default:
            filterComponent = (
                <input
                    type="text"
                    onChange={(e) => onFilter(field, e.target.value)}
                    onClick={e => e.stopPropagation()}
                    placeholder={`Filter ${title}...`}
                    className="rich-table-filter-input rich-table-filter-input-text"
                />
            )
            break
    }

    return (
        <div className='rich-table-filter'>
            {filterComponent}
        </div>
    )
}