import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

function AlertSettings(props) {
    const [strategies, setStrategies] = useState([]);
    const [symbols, setSymbols] = useState([]);
    const [symbol, setSymbol] = useState(null)
    const [algoName, setAlgoName] = useState('')
    const [timeframe, setTimeframe] = useState(0)
    const [selectedStrategyName, setSelectedStrategyName] = useState("Select strategy");
    const [selectedStrategyId, setSelectedStrategyId] = useState(0);

    useEffect(() => {
        (async function () {
            const strategiesText = await (await fetch(`/api/strategies`)).json();
            setStrategies(strategiesText);

            const syms = await (await fetch(`/api/strategySymbols`)).json();
            console.log("setting symbols", syms);
            setSymbols(syms);
        })();
    }, []);

    function onStrategyDropdownSelected(id) {
        console.log("onStrategyDropdownSelected", id);
        const strategy = strategies.find(s => s.idtrading_strategies === Number(id));
        console.log("strategy", strategy);
        if (strategy) {
            setSelectedStrategyName(strategy.name);
            setSelectedStrategyId(strategy.idtrading_strategies);
            props.onAlertUpdated(strategy.idtrading_strategies, symbol, timeframe, algoName);
        }
    }

    function onStrategySymbolSelected(symbol) {
        console.log("onStrategySymbolSelected", symbol);
        setSymbol(symbol);
        props.onAlertUpdated(selectedStrategyId, symbol, timeframe, algoName);
    }

    function onStrategyTimeframeSelected(t) {
        console.log("onStrategyTimeframeSelected", t);
        setTimeframe(Number(t));
        props.onAlertUpdated(selectedStrategyId, symbol, Number(t), algoName);
    }

    function onAlgoNameUpdate(name) {
        setAlgoName(name);
        props.onAlertUpdated(selectedStrategyId, symbol, timeframe, name);
    }

    return (
        <>
            <>
                <table width="100%">
                    <tr>
                        <td>
                            <Dropdown width="150" onSelect={(e) => onStrategyDropdownSelected(e)}>
                                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                    {selectedStrategyName}
                                </Dropdown.Toggle>

                                <Dropdown.Menu  >
                                    {strategies.sort((l, r) => l.name < r.name ? -1 : 1).map(strategy => {
                                        return (<Dropdown.Item as='strategyId' eventKey={strategy.idtrading_strategies} value={strategy.name}>{strategy.name}</Dropdown.Item>)
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </td>
                        <td>
                            <Dropdown width="150" onSelect={(e) => onStrategySymbolSelected(e)}>
                                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                    {symbol ? symbol : "Select symbol"}
                                </Dropdown.Toggle>

                                <Dropdown.Menu  >
                                    {symbols.sort().map(sym => {
                                        return (<Dropdown.Item as='symbol' eventKey={sym} value={sym}>{sym}</Dropdown.Item>)
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </td>
                        <td>
                            <Dropdown width="150" onSelect={(e) => onStrategyTimeframeSelected(e)}>
                                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                    {timeframe === 0 ? "Daily" : "Weekly"}
                                </Dropdown.Toggle>

                                <Dropdown.Menu  >
                                    <Dropdown.Item as='daily' eventKey={0} value={0}>Daily</Dropdown.Item>
                                    <Dropdown.Item as='weekly' eventKey={1} value={1}>Weekly</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </td>
                        {
                            !props.hideAlgoName ?
                                <td>
                                    Algo identifier: <input type="text" size="20" defaultValue="" onChange={(e) => onAlgoNameUpdate(e.target.value)}></input>
                                </td>
                                :
                                null
                        }
                    </tr>
                </table>
            </>

        </>
    );
}

export default AlertSettings;