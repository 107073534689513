import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import tableHelpers from './TableHelpers.js'
import { Chart } from "react-google-charts";
import RichTable from './components/richTable/RichTable.jsx'
import { createDateSorter } from './components/richTable/sorters.js'

function ActiveTrades(props) {
    const [data, setData] = useState([]);
    const [refreshCount, setRefreshCount] = useState(0);

    useEffect(() => {
        (function () {

            let url = "";
            if (props.planId && props.planId !== "") {
                url = `/api/activeTrades/?planId=` + props.planId
            } else {
                url = `/api/allActiveTrades`;
            }

            fetch(url)
                .then(res => res.json())
                .then(text => {
                    setData(text);
                });

        })();
    }, [props.planId, refreshCount]);

    function getQuote(trade) {
        return trade.current_price;
    }

    function getProfit(trade) {
        var quote = trade.current_price;
        if (quote && trade.close) {
            return ((quote - trade.close) / trade.close * 100);
        } else
            return 0;
    }

    function getDollarProfit(trade) {
        var quote = trade.current_price;
        if (quote && trade.close) {
            return (quote - trade.close);
        } else
            return 0;
    }

    function getProfitImpact(trade) {
        return getProfit(trade) * trade.allocation / 100 * (trade.percent_of_allocation / 100);
    }

    function getTotalProfit() {
        let total = 0.0;
        data.forEach(trade => {
            var quote = trade.current_price;
            if (quote && trade.close) {
                const profit = ((quote - trade.close) / trade.close * 100) *
                    trade.allocation / 100 * (trade.percent_of_allocation / 100);
                total += profit;
            }
        })
        return total;
    }

    function handleRefreshClicked() {
        setRefreshCount(refreshCount + 1)
    }

    function onAlgoClicked(algoId) {
        console.log("onAlgoClicked", algoId, props.onAlgoSelected)
        if (props.onAlgoSelected)
            props.onAlgoSelected(algoId);
    }

    function getTrades() {
        return data.map(trade => {
            return {
                purchase_date: trade.purchase_date,
                display_name: trade.display_name,
                algoId: trade.algoId,
                trade_symbol: trade.trade_symbol,
                close: trade.close,
                quote: getQuote(trade),
                profit: getProfit(trade),
                allocation: trade.allocation * (trade.percent_of_allocation / 100),
                netProfit: getProfitImpact(trade),
                dollarProfit: props.accountValue ? props.accountValue * (getProfitImpact(trade) / 100) : getDollarProfit(trade),
                winning_percentage: trade.winning_percentage,
                avg_duration: trade.avg_duration,
                avg_profit: trade.avg_profit,
            }
        });
    }

    function algoLinkFormatter(cell, row) {
        let algoid = row.algoId;
        return (
            <Link className="bg-transparent" to={"/algo"} onClick={() => onAlgoClicked(algoid)}>{cell}</Link>
        )
    }

    function getStats() {

        let stats = { count: 0, winners: 0, losers: 0, avgProfit: 0.0, avgNetProfit: 0.0, avgWin: 0.0, avgLoss: 0.0, maxWin: 0.0, maxLoss: 0.0, netProfit: 0.0 }

        data.forEach(trade => {
            let profit = getProfit(trade);
            let netProfit = getProfitImpact(trade);
            stats.avgProfit = ((stats.avgProfit * stats.count) + profit) / (stats.count + 1);
            stats.avgNetProfit = ((stats.avgNetProfit * stats.count) + netProfit) / (stats.count + 1);

            if (profit > 0) {
                if (profit > stats.maxWin) stats.maxWin = profit;
                stats.avgWin = ((stats.avgWin * stats.winners) + profit) / (stats.winners + 1);
                ++stats.winners;
            } else if (profit < 0) {
                if (profit < stats.maxLoss) stats.maxLoss = profit;
                stats.avgLoss = ((stats.avgLoss * stats.losers) + profit) / (stats.losers + 1);
                ++stats.losers;
            }
            ++stats.count;
        });
        stats.winningPct = (stats.winners / (stats.count) * 100);

        return [{
            netProfit: getTotalProfit(),
            dollarProfit: getTotalProfit() / 100 * props.accountValue,
            winners: stats.winners,
            losers: stats.losers,
            count: stats.count,
            avgProfit: stats.avgProfit,
            avgNetProfit: stats.avgNetProfit,
            avgWin: stats.avgWin,
            avgLoss: stats.avgLoss,
            maxWin: stats.maxWin,
            maxLoss: stats.maxLoss,
            winningPct: stats.winningPct
        }]
    }

    function getChartData() {
        let chartData = [];
        data.forEach(trade => {
            const profit = getProfit(trade);
            chartData.push([trade.display_name + "(" + trade.trade_symbol + ")", profit / 100, profit >= 0 ? "#B2D8B2" : "#B85D5D"])
        });
        chartData.sort((a, b) => {
            return a[1] - b[1];
        })

        if (chartData.length)
            chartData.unshift(['Algo', 'P/L', { role: "style" }]);

        return chartData;
    }

    function getBubbleChartData() {
        let bubbleChartData = [["Id", "Days", "Profit", "Algo", "Allocation"]]
        const trades = getTrades();
        trades.forEach(trade => {
            const profit = trade.profit / 100;
            let days = Math.round((new Date() - new Date(trade.purchase_date)) / (1000 * 60 * 60 * 24));
            bubbleChartData.push(["", days, profit, trade.display_name, trade.allocation])
        })
        console.log("bubbleChartData", bubbleChartData);
        return bubbleChartData;
    }

    const chartData = getChartData();
    const max = tableHelpers.getMaxChartValue(chartData, 1);
    const min = tableHelpers.getMinChartValue(chartData, 1);
    var chartOptions = {
        title: "P/L by Trade", legend: { position: 'none' },
        vAxis: {
            format: '#%',
            viewWindowMode: 'explicit',
            viewWindow: {
                max: max + Math.abs(max) * .1,
                min: min - Math.abs(min) * .1
            },
        },
        bar: { width: "25" },
    }

    const statsMapper = [
        { title: `Count`, field: 'count', formatter: tableHelpers.intFormatter },
        { title: `Winning %`, field: 'winningPct', formatter: tableHelpers.percentFormatter },
        { title: `Net`, field: 'netProfit', formatter: tableHelpers.percentFormatter },
        { title: `Avg P/L`, field: 'avgProfit', formatter: tableHelpers.percentFormatter },
        { title: `Avg Net Profit`, field: 'avgNetProfit', formatter: tableHelpers.percentFormatter, hidden: props.mobileView },
        { title: `Avg Win`, field: 'avgWin', formatter: tableHelpers.percentFormatter, hidden: props.mobileView },
        { title: `Avg Loss`, field: 'avgLoss', formatter: tableHelpers.percentFormatter, hidden: props.mobileView },
        { title: `Max Win`, field: 'maxWin', formatter: tableHelpers.percentFormatter, hidden: props.mobileView },
        { title: `Max Loss`, field: 'maxLoss', formatter: tableHelpers.percentFormatter, hidden: props.mobileView },
    ]

    const tradesMapper = [
        { title: `Purch Date`, field: 'purchase_date', formatter: tableHelpers.dateFormmatter, sorters: createDateSorter('purchase_date'), hidden: props.mobileView, isDefaultSort: true },
        { title: `Symbol`, field: 'trade_symbol', sorters: true },
        { title: `Algo`, field: 'display_name', sorters: true, formatter: algoLinkFormatter },
        { title: `Cost`, field: 'close', sorters: true, formatter: tableHelpers.moneyFormatter, hidden: props.mobileView },
        { title: `Current Price`, field: 'quote', sorters: true, formatter: tableHelpers.moneyFormatter, hidden: props.mobileView },
        { title: `P/L`, field: 'profit', sorters: true, formatter: tableHelpers.percentFormatter },
        { title: `Allocation`, field: 'allocation', sorters: true, formatter: tableHelpers.percentFormatter, hidden: !(props.planId && props.planId !== "") },
        { title: `Net Profit`, field: 'netProfit', sorters: true, formatter: tableHelpers.percentFormatter, hidden: props.mobileView || !(props.planId && props.planId !== "") },
        { title: `Win %`, field: 'winning_percentage', sorters: true, formatter: tableHelpers.percentFormatter, hidden: props.mobileView },
        { title: `Avg P/L`, field: 'avg_profit', sorters: true, formatter: tableHelpers.percentFormatter, hidden: props.mobileView },
        { title: `Avg Days`, field: 'avg_duration', sorters: true, formatter: tableHelpers.intFormatter, hidden: props.mobileView },
    ]

    return (
        <>
            <br></br>
            <RichTable data={getStats()} mappers={statsMapper} className="table-striped table-hover table-condensed" />

            {chartData.length ?
                <table width="100%">
                    <tr>
                        <td width="50%">
                            <Chart
                                chartType="ColumnChart"
                                data={chartData}
                                height={"300px"}
                                width={"100%"}
                                options={chartOptions}
                                chartPackages={["corechart", "controls"]}
                            />
                        </td>
                        <td>
                            <Chart
                                chartType="BubbleChart"
                                data={getBubbleChartData()}
                                width={"100%"}
                                options={{
                                    hAxis: { title: "Days Open" },
                                    vAxis: { title: "Profit %", format: 'percent' },
                                    bubble: { textStyle: { fontSize: 0 } },
                                }}
                                chartPackages={["corechart", "controls"]}
                            />
                        </td>
                    </tr>
                </table >


                :
                null
            }

            <div>
                <center>
                    <Link className="bg-transparent" onClick={handleRefreshClicked}>Refresh</Link>
                </center>
            </div>


            <div style={{ width: '100%' }}>
                <RichTable data={getTrades()} mappers={tradesMapper} className="table-striped table-hover table-condensed" />
            </div >
            <br></br>
            <br></br>
        </>
    );
}

export default ActiveTrades;