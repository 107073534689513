import React, { useState, useEffect } from 'react';
import DailyBalanceChart from './DailyBalanceChart.js'
import tableHelpers from './TableHelpers.js'
import RichTable from './components/richTable/RichTable.jsx'
import backtestResultsHelpers from './BacktestResultsHelpers.js'
import MonthlyBacktestResults from './MonthlyBacktestResults.js';

function PlanRecap(props) {

    const [performanceResults, setPerformanceResults] = useState({});
    const [chartData, setChartData] = useState([]);
    const [completedTrades, setCompletedTrades] = useState({})
    useEffect(() => {
        (async function () {

            const loadPerformanceResults = async () => {
                console.log("Loading performance results...");
                const url = `/api/lastBacktestResults?planId=` + props.planId + "&liveTradesOnly=1";
                let text = await (await fetch(url)).json();

                const tempChartData = [];
                text.dailyResults.forEach(day => tempChartData.push([new Date(day.date), day.total, day.baseline]))
                setChartData(tempChartData)
                setPerformanceResults(text);
            }

            const loadCompletedTrades = async () => {
                const url = `/api/completedTrades?planId=` + props.planId + "&includeStats=1&startDate=2024-1-1";
                let text = await (await fetch(url)).json();

                setCompletedTrades(text);
            }

            loadPerformanceResults();
            loadCompletedTrades();
        })();
    }, [props.planId]);

    function getRiskRecapArray() {
        let avgLoss = 0;
        let maxLoss = 0;
        let avgAllocation = 0;

        if (completedTrades && completedTrades.stats) {
            avgLoss = completedTrades.stats.avgNetLoss;
            maxLoss = completedTrades.stats.maxNetLoss;
            avgAllocation = completedTrades.stats.avgAllocation;
        }

        let currentYear = new Date().getUTCFullYear();
        let result = [
            {
                name: 'Current Drawdown',
                result: backtestResultsHelpers.getCurrentDrawdown(performanceResults, false),
                format: 'percent'
            },
            {
                name: 'Max Drawdown',
                result: backtestResultsHelpers.getMaxDrawdownSince(performanceResults, new Date(currentYear, 0, 1)),
                format: 'percent'
            },
            {
                name: 'Max S&P 500 Drawdown',
                result: backtestResultsHelpers.getMaxDrawdownSince(performanceResults, new Date(currentYear, 0, 1), true),
                format: 'percent'
            },
            {
                name: "Average allocation per trade",
                result: avgAllocation,
                format: 'percent'
            },
            {
                name: "Average Net Loss per Losing Trade",
                result: avgLoss,
                format: 'percent'
            },
            {
                name: "Biggest Net Loss on a Losing Trade",
                result: maxLoss,
                format: 'percent'
            },
        ];
        return result;
    }

    function getRecapArray() {
        let count = 0;
        let winRate = 0;
        let avgProfit = 0;
        let avgNetWin = 0;
        let avgNetProfit = 0;
        let maxNetWin = 0;

        if (completedTrades && completedTrades.stats) {
            winRate = completedTrades.stats.winningPct;
            avgProfit = completedTrades.stats.avgProfit;
            avgNetProfit = completedTrades.stats.avgNetProfit;
            avgNetWin = completedTrades.stats.avgNetWin;
            count = completedTrades.stats.count;
            maxNetWin = completedTrades.stats.maxNetWin;
        }

        let currentYear = new Date().getUTCFullYear();
        let result = [
            {
                name: 'YTD Profit/Loss',
                result: backtestResultsHelpers.getReturnSince(performanceResults, new Date(currentYear, 0, 1), false),
                format: 'percent'
            },
            {
                name: 'S&P 500 YTD Profit/Loss',
                result: backtestResultsHelpers.getReturnSince(performanceResults, new Date(currentYear, 0, 1), true),
                format: 'percent'
            },
            {
                name: 'Trade Count',
                result: count,
                format: 'int'
            },
            {
                name: 'Percent Profitable Trades ',
                result: winRate,
                format: 'percent'
            },
            {
                name: 'Average Profit/Loss per Trade',
                result: avgProfit,
                format: 'percent'
            },
            {
                name: 'Avg Net Profit per Trade',
                result: avgNetProfit,
                format: 'percent'
            },
            {
                name: 'Average Net Profit per Winning Trade',
                result: avgNetWin,
                format: 'percent'
            },
            {
                name: 'Biggest Net Profit on a Winning Trade',
                result: maxNetWin,
                format: 'percent'
            },
        ];

        return result;
    }

    const recapMapper = [
        { title: 'Category', field: 'name' },
        { title: 'Result', field: 'result', formatter: tableHelpers.dynamicFormatter },
    ]

    return (
        <>
            <div>

                <br></br>

                <h3>Performance</h3>
                <DailyBalanceChart hideOptions={true} defaultToYtd={true} defaultToSyncValues={true} backtestResults={performanceResults} chartData={chartData} hideAllocationChart={true} hideChartSummary={true}></DailyBalanceChart>

                <RichTable data={getRecapArray()} mappers={recapMapper} className="table-striped table-hover table-condensed" />

                <br></br>
                <h3>Risk</h3>
                <DailyBalanceChart hideOptions={true} defaultToDrawdown={true} defaultToYtd={true} defaultToSyncValues={true} backtestResults={performanceResults} chartData={chartData} hideAllocationChart={true} hideChartSummary={true}></DailyBalanceChart>

                <RichTable data={getRiskRecapArray()} mappers={recapMapper} className="table-striped table-hover table-condensed" />

                <br></br>
                <h3>Monthly Returns</h3>
                <MonthlyBacktestResults currentYearOnly={true} hideOptions={false} hideChart={true} backtestResults={performanceResults} />
            </div>
            <br></br>
            <br></br>
        </>
    );
}
export default PlanRecap; 