import React, { useState, useEffect } from 'react';
import AlertEvaluationResults from './AlertEvaluationResults.js';
import AlertConfiguration from './AlertConfiguration.js';
import AlertSettings from './AlertSettings.js';
import StockChart from './StockChart.js';
import AlertDataSample from './AlertDataSample.js';
import QuoteDateAnalysis from './QuoteDateAnalysis.js';

function AlertSandbox(props) {

    const [config, setConfig] = useState({})
    const [defaultConfig, setDefaultConfig] = useState({})
    const [performance, setPerformance] = useState({})
    const [performanceArray, setPerformanceArray] = useState([])
    const [symbol, setSymbol] = useState(null)
    const [timeframe, setTimeframe] = useState(0)
    const [updates, setUpdates] = useState(0);
    const [selectedStrategyId, setSelectedStrategyId] = useState(0);
    const [startDate, setStartDate] = useState(new Date(2014, 0, 1));
    const [endDate, setEndDate] = useState(new Date());
    const [addRandomness, setAddRandomness] = useState(false);

    useEffect(() => {
        (async function () {

            if (selectedStrategyId) {
                console.log("Loading default config", selectedStrategyId);
                setSelectedStrategyId(selectedStrategyId);

                let text = await (await fetch(`/api/strategyDefaultConfig?strategyId=` + selectedStrategyId)).json();
                if (text) {
                    setConfig(text);
                    setDefaultConfig(text);
                }
            }
        })();
    }, [selectedStrategyId, updates]);

    async function handleReset() {
        setUpdates(updates + 1);
    }

    async function handleEvaluate(conf = null, start = null, end = null) {
        if (selectedStrategyId && symbol) {

            console.log("addRandomness", addRandomness);

            let startToUse = start ? start : startDate;
            let endToUse = end ? end : endDate;
            let url = "/api/strategySandboxEvaluator?strategyId=" + selectedStrategyId + "&symbol=" + symbol + "&timeframe=" + timeframe
            if (startDate) url += "&startDate=" + startToUse.getFullYear().toString() + "-" + (startToUse.getMonth() + 1).toString() + "-" + (startToUse.getDate()).toString();
            if (endDate) url += "&endDate=" + endToUse.getFullYear().toString() + "-" + (endToUse.getMonth() + 1).toString() + "-" + (endToUse.getDate()).toString();
            url += "&addRandomness=" + (addRandomness ? 'true' : 'false');
            const configToUse = conf ? JSON.stringify(conf) : JSON.stringify(config)
            console.log("handleEvaluate", configToUse);
            console.log("handleEvaluate", url);

            const r = await (await fetch(url, {
                method: 'POST',
                body: configToUse
            })).json();
            console.log(r);
            setPerformance(r);

            let perfArray = [];
            perfArray.push({ id: performanceArray.length + 1, ...r });
            performanceArray.forEach(p => {
                perfArray.push(p);
            })
            setPerformanceArray(perfArray);
        }
    }

    function handleClearResults() {
        setPerformanceArray([]);
    }

    function handleConfigUpdate(c) {
        console.log(c);
        setConfig(c);
        handleEvaluate(c);
    }

    function handleMaxConfigUpdated(c) {
        console.log(c);
    }

    function handleAlertUpdated(strategyId, symbol, timeframe, name) {
        console.log(strategyId, symbol, timeframe, name);
        setSelectedStrategyId(strategyId);
        setSymbol(symbol);
        setTimeframe(timeframe);
    }

    function onDataSampleUpdated(startDate, endDate) {
        setStartDate(startDate);
        setEndDate(endDate);
        handleEvaluate(null, startDate, endDate);
    }

    function onAddRandomnessUpdated(shouldAddRandomness) {
        setAddRandomness(shouldAddRandomness);
    }

    function getTrades() {
        if (performance && performance.trades && performance.trades.length) {
            return performance.trades.map(t => {
                return {
                    purchase_date: t.purchaseDate,
                    purchase_price: t.purchasePrice,
                    sell_date: t.sellDate,
                    sell_price: t.sellPrice,
                    profitPercentage: t.profitPercentage
                }
            })
        } else {
            return [];
        }
    }

    return (
        <div>
            <table width="500px">
                <AlertSettings onAlertUpdated={handleAlertUpdated} hideAlgoName={true} /><br></br>
            </table>

            <table>
                <tr>
                    <td>
                        <AlertConfiguration config={config} defaultConfig={defaultConfig} onConfigUpdated={handleConfigUpdate} onMaxConfigUpdated={handleMaxConfigUpdated} showMaxConfig={false} />
                    </td>
                    <td align='left'>
                        <StockChart symbol={symbol} trades={getTrades()} />
                    </td>
                </tr>
            </table>

            <AlertDataSample onDataSampleUpdated={onDataSampleUpdated} onAddRandomnessUpdated={onAddRandomnessUpdated} />

            <>
                <br></br>
                <button onClick={() => handleReset()}>Reset</button>
                &nbsp;&nbsp;&nbsp;<button onClick={() => handleEvaluate()}>Evaluate</button>
                &nbsp;&nbsp;&nbsp;<button onClick={handleClearResults}>Clear Results</button>
            </>

            <br></br>
            <br></br>
            <QuoteDateAnalysis symbol={symbol} trades={performance.trades} />

            <AlertEvaluationResults performance={performance} performanceArray={performanceArray} shouldShowTrades={false} />

        </div >);
}

export default AlertSandbox;