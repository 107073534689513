import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";
import DatePicker from "react-datepicker";
import tableHelpers from './TableHelpers.js'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import RichTable from './components/richTable/RichTable.jsx'


function StressTest(props) {
    let fiveYearsAgo = new Date().setMonth(new Date().getMonth() - 5 * 12);
    const [startDate, setStartDate] = useState(new Date(fiveYearsAgo))
    const [endDate, setEndDate] = useState(new Date())
    const [updates, setUpdates] = useState(0);
    const [randomizeOrder, setRandomizeOrder] = useState(true);
    const [slippageRate, setSlippageRate] = useState(5);
    const [lossRate, setLossRate] = useState(5);
    const [skippedTrades, setSkippedTrades] = useState(0);
    const [results, setResults] = useState([]);
    const [simulationResults, setSimulationResults] = useState([]);
    const [trades, setTrades] = useState([]);
    const [planAlgos, setPlanAlgos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showEachSimulation, setShowEachSimulation] = useState(true);

    useEffect(() => {
        (async function () {

            setLoading(true);
            setTrades([]);
            setSimulationResults([]);
            setResults([])


            let plan = await (await fetch(`/api/algos?planId=` + props.planId)).json();
            setPlanAlgos(plan);

            let algos = "";
            plan.forEach(a => {
                algos += a.idalgos + ",";
            })

            const query = `/api/backtestTrades?algos=` + algos +
                "&startDate=" + startDate.toLocaleDateString() +
                "&endDate=" + endDate.toLocaleDateString();

            let trds = await (await fetch(query)).json();
            setTrades(trds);
            setLoading(false);
        })();
    }, [props.planId, startDate, endDate, slippageRate, lossRate, skippedTrades, randomizeOrder]);


    async function getData() {
        let copyOfTrades = JSON.parse(JSON.stringify(trades));
        let disruptedTrades = disruptTrades(copyOfTrades);
        await runSimulation(disruptedTrades, planAlgos);
    }

    function disruptTrades(trades) {
        console.log("Disrupting trades");
        if (lossRate > 0) {
            console.log("Injecting losses")
            let effectiveLossRate = parseInt(lossRate);
            let forceLoss = false;
            for (var i = 0; i < trades.length; i++) {
                let rando = Math.random() * 100;
                if (forceLoss || rando < effectiveLossRate) {
                    if (trades[i].profit < 0)
                        forceLoss = true;
                    else {
                        const sell = trades[i].sell_price;
                        trades[i].sell_price = trades[i].purchase_price;
                        trades[i].purchase_price = sell;
                        trades[i].profit *= -1;
                        forceLoss = false;
                    }
                }
            }
        }

        if (skippedTrades > 0) {
            const skippedRate = parseInt(skippedTrades);
            console.log("Injecting skips")
            let forceSkip = false;
            for (var c = 0; c < trades.length; c++) {
                let rando = Math.random() * 100;
                if (forceSkip || rando < skippedRate) {
                    if (trades[c].profit > 0) {
                        trades[c].profit = 0;
                        trades[c].sell_date = null;
                        forceSkip = false;
                    } else {
                        forceSkip = true;
                    }
                }
            }
        }

        if (slippageRate > 0) {
            const slippage = parseInt(slippageRate);
            console.log("Injecting slippage")
            for (var a = 0; a < trades.length; a++) {
                if (trades[a].profit > 0)
                    trades[a].profit *= ((100 - slippage) / 100);
                else {
                    const multiplier = (100 + slippage) / 100;
                    trades[a].profit *= multiplier;
                }
            }
        }

        if (randomizeOrder) {
            console.log("Randomizing order of " + trades.length + " trades")
            for (var x = trades.length - 1; x > 0; x--) {
                var y = Math.floor(Math.random() * (x - 1));
                if (trades[x].sell_date !== null && trades[y].sell_date !== null) {
                    var temp = trades[x];
                    trades[x].sell_date = trades[y].sell_date;
                    trades[x].purchase_date = trades[y].purchase_date;
                    trades[y].sell_date = temp.sell_date;
                    trades[y].purchase_date = temp.purchase_date;
                }
            }
        }
        return trades;
    }

    function getStandardDeviation(array) {
        const n = array.length
        if (n === 0) return 0;
        const mean = array.reduce((a, b) => a + b) / n
        return Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n)
    }

    function monthDiff(d1, d2) {
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    }

    function getResults() {
        let ret = { count: 0, years: 0, profitSum: 0, profit: 0, winRate: 0, avgProfit: 0, trades: 0, maxProfit: null, minProfit: null, stddev: 0, oneStdDev: '', twoStdDev: '', annualProfit: 0 };
        const months = monthDiff(startDate, endDate);
        const years = Math.round(months / 12);
        ret.years = years;

        let annualProfits = [];
        for (let i = 0; i < results.length; i++) {
            ret.profitSum += results[i].profit;
            ret.trades += results[i].trades;
            ret.winRate = ((ret.winRate * ret.count) + results[i].winRate) / (ret.count + 1);
            ++ret.count;

            let annualProfit = (Math.pow((1 + results[i].profit / 100), (1 / years)) - 1) * 100;
            console.log("annualProfit", annualProfit);
            if (ret.maxProfit === null || annualProfit > ret.maxProfit) ret.maxProfit = annualProfit;
            if (ret.minProfit === null || annualProfit < ret.minProfit) ret.minProfit = annualProfit;
            annualProfits.push(annualProfit);
        }

        ret.profit = ret.profitSum / ret.count;
        //(1 + Return) ^ (1 / N) - 1 = Annualized Return
        ret.annualProfit = (Math.pow((1 + ret.profit / 100), (1 / years)) - 1) * 100;
        ret.avgProfit = ret.profitSum / ret.trades;
        ret.stddev = getStandardDeviation(annualProfits.map(r => r));
        ret.oneStdDev = (ret.annualProfit - ret.stddev).toFixed(0) + "% - " + (ret.annualProfit + ret.stddev).toFixed(0) + "%";
        ret.twoStdDev = (ret.annualProfit - 2 * ret.stddev).toFixed(0) + "% - " + (ret.annualProfit + 2 * ret.stddev).toFixed(0) + "%";

        return [ret];
    }

    async function runSimulation(trades, algoList) {
        const end = new Date(endDate);
        let date = new Date(startDate);
        let balance = 100000;
        let testRunResults = [];
        let wins = 0;
        let losses = 0;
        let summedProfit = 0;
        while (date <= end) {
            for (const trade of trades) {
                const closeDate = new Date(trade.purchase_date);
                if (date.getFullYear() === closeDate.getFullYear() &&
                    date.getMonth() === closeDate.getMonth() &&
                    date.getDate() === closeDate.getDate()) {

                    const algo = algoList.find(a => a.idalgos === trade.algoId);

                    const profit = trade.profit / 100 * (algo.percentage / 100);
                    if (profit !== 0) {
                        balance *= (1 + profit);

                        if (profit > 0) ++wins;
                        else if (profit < 0) ++losses;
                        summedProfit += (trade.profit / 100 * (algo.percentage / 100)) * 100
                    }
                }
            }
            const net = (balance - 100000) / 100000;
            testRunResults.push({
                date: new Date(date),
                net: [net]
            })
            date.setDate(date.getDate() + 1)
        }


        let allResults = results;

        if (simulationResults.length === testRunResults.length) {
            addSimulationResults(testRunResults);
        } else {
            allResults = [];
            setSimulationResults(testRunResults);
        }

        allResults.push({
            testRun: updates,
            randomizeOrder: randomizeOrder ? 1 : 0,
            slippageRate: slippageRate,
            lossRate: lossRate,
            skippedTrades: skippedTrades,
            profit: (balance - 100000) / 100000 * 100,
            winRate: wins / (wins + losses) * 100,
            avgProfit: summedProfit / (wins + losses),
            trades: wins + losses
        })
        setResults(allResults);
    }

    function addSimulationResults(testRunResults) {
        let newSimulationResults = JSON.parse(JSON.stringify(simulationResults));

        for (let i = 0; i < testRunResults.length; i++) {
            let newDailyResult = testRunResults[i];
            let oldResult = newSimulationResults[i].net;
            oldResult.push(newDailyResult.net[0]);
        }

        setSimulationResults(newSimulationResults);
    }

    function getSimulationChartData() {
        let chartData = [];

        let maxValue = 0;
        let minValue = 0;

        simulationResults.forEach(result => {
            let dailyValue = [new Date(result.date)];
            let dailyMin = null;
            let dailyMax = null;
            let dailySum = 0;
            for (let i = 0; i < result.net.length; i++) {
                if (showEachSimulation)
                    dailyValue.push(result.net[i])

                if (result.net[i] > maxValue) maxValue = result.net[i];
                if (result.net[i] < minValue) minValue = result.net[i];
                if (dailyMin === null || result.net[i] < dailyMin) dailyMin = result.net[i];
                if (dailyMax === null || result.net[i] > dailyMax) dailyMax = result.net[i];
                dailySum += result.net[i];
            }
            if (!showEachSimulation) {
                dailyValue.push(dailyMin);
                dailyValue.push(dailyMax);
                dailyValue.push(dailySum / result.net.length)
            }
            chartData.push(dailyValue)
        })

        if (chartData.length) {
            let header = ['Date'];
            if (showEachSimulation) {
                for (let i = 1; i < chartData[0].length; i++) {
                    header.push('Sim');
                }
            } else {
                header.push('Min');
                header.push('Max');
                header.push('Mean');
            }
            chartData.unshift(header);
        }

        return [chartData, minValue, maxValue];
    }

    async function handleRunSimulation() {
        await getData();
        setUpdates(updates + 1);
    }

    function handleYearRangeClicked(years) {
        let d = new Date();
        d.setFullYear(d.getFullYear() - years);
        setStartDate(d);
        setEndDate(new Date());
    }

    function handleYearClicked(year) {
        let start = new Date(year, 0, 1);
        let end = new Date(year, 11, 31);
        setStartDate(start);
        setEndDate(end);
    }

    const resultsMapper = [
        { title: `Test Runs`, field: 'count', formatter: tableHelpers.intFormatter },
        { title: `Years`, field: 'years', formatter: tableHelpers.intFormatter },
        { title: `Avg Annual Profit`, field: 'annualProfit', formatter: tableHelpers.percentFormatter },
        { title: `Annual StdDev`, field: 'stddev', formatter: tableHelpers.percentFormatter },
        { title: `68% Confidence`, field: 'oneStdDev' },
        { title: `95% Confidence`, field: 'twoStdDev' },
        { title: `Min Profit`, field: 'minProfit', formatter: tableHelpers.percentFormatter },
        { title: `Max Profit`, field: 'maxProfit', formatter: tableHelpers.percentFormatter },
        { title: `Trades`, field: 'trades', formatter: tableHelpers.intFormatter },
        { title: `Win %`, field: 'winRate', formatter: tableHelpers.percentFormatter },
    ]

    let simulationChartData = getSimulationChartData();
    let chartData = simulationChartData[0];
    let minValue = simulationChartData[1];
    let maxValue = simulationChartData[2];
    const options = {
        title: "Realized P/L",
        vAxis: {
            format: '#%',
            viewWindowMode: 'explicit',
            viewWindow: {
                max: maxValue + (Math.abs(maxValue) * .1),
                min: minValue - (Math.abs(minValue) * .1)
            },
            minValue: maxValue + (Math.abs(maxValue) * .1),
            maxValue: minValue - (Math.abs(minValue) * .1)
        },
        chartArea: { 'width': '75%', 'height': '80%' },
        legend: { position: 'none' }
    };

    return (
        <>
            <br></br>
            <center>
                <h3>Sample Timeframe</h3>
                Start Date: <DatePicker showYearPicker disabledKeyboardNavigation className="bg-transparent" selected={startDate} onChange={(date) => setStartDate(date)} />&nbsp;&nbsp;
                End Date: <DatePicker showYearPicker className="bg-transparent" selected={endDate} onChange={(date) => setEndDate(date)} />
                <br></br>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearRangeClicked(1) }}>1 Year</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearRangeClicked(2) }}>2 Years</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearRangeClicked(3) }}>3 Years</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearRangeClicked(5) }}>5 Years</Link>
                <br></br>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2024) }}>2024</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2023) }}>2023</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2022) }}>2022</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2021) }}>2021</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2020) }}>2020</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2019) }}>2019</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2018) }}>2018</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2017) }}>2017</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2016) }}>2016</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2015) }}>2015</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2014) }}>2014</Link>
                <br></br>
                <br></br>
                Randomize Order:<input type="checkbox" defaultChecked={true} onChange={(e) => setRandomizeOrder(!randomizeOrder)}></input>&nbsp;&nbsp;
                P/L Slippage %: <input type="text" size="3" defaultValue={5} onChange={(e) => setSlippageRate(e.target.value)}></input>&nbsp;&nbsp;
                Extra Loss Rate %: <input type="text" size="3" defaultValue={5} onChange={(e) => setLossRate(e.target.value)}></input>&nbsp;&nbsp;
                Skipped Winners %: <input type="text" size="3" defaultValue={0} onChange={(e) => setSkippedTrades(e.target.value)}></input>&nbsp;&nbsp;
                <br></br>
                Show Each Simulation<input type="checkbox" defaultChecked={true} onChange={() => setShowEachSimulation(!showEachSimulation)}></input>&nbsp;&nbsp;

                <br></br><br></br><Button disabled={loading} className="btn btn-primary" onClick={() => handleRunSimulation()}>{loading ? "Loading" : "Simulate"}</Button>
            </center>

            {chartData.length <= 1 ?
                <></>
                :
                <div>
                    <center>
                        <Chart
                            chartType="LineChart"
                            data={chartData}
                            width={"100%"}
                            height={"400px"}
                            options={options}
                            chartPackages={["corechart", "controls"]}
                        />
                    </center>
                </div>
            }

            <RichTable data={getResults()} mappers={resultsMapper} className="table-striped table-hover table-condensed" />
        </>
    );
}

export default StressTest;