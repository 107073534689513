import React, { useState } from 'react';
import { Table } from 'react-bootstrap'
import './index.css'

function CreateAlgo(props) {

    const [name, setName] = useState('')
    const [displayName, setDisplayName] = useState('')
    const [baseSymbol, setBaseSymbol] = useState('')
    const [tradeSymbol, setTradeSymbol] = useState('')
    const [type, setType] = useState('Mean Reversion')
    const [short, setShort] = useState(false)
    const [result, setResult] = useState('')

    function shouldAllowEdits() {
        if (props.role === 3) return true;
        else return false;
    }

    async function handleSubmit() {
        console.log("handleSubmit");
        const url = "/api/algoCreate?name=" + name + "&displayName=" + displayName + "&baseSymbol=" + baseSymbol + "&tradeSymbol=" + tradeSymbol + "&short=" + (short ? "1" : "0") + "&pyramids=1&type=" + type;
        console.log(url);
        const r = await (await fetch(url)).json();
        setResult(r);
    }

    return (
        <div>
            {shouldAllowEdits() ?
                <Table border="1">
                    <tr>
                        <th>Name</th>
                        <th>Display Name</th>
                        <th>Base Symbol</th>
                        <th>Trade Symbol</th>
                        <th>Type</th>
                        <th>Is Short</th>
                        <th>Pyramids</th>
                        <th>Manage</th>
                    </tr>
                    <tr>
                        <td><input type="text" size="20" onChange={(e) => setName(e.target.value)}></input></td>
                        <td><input type="text" size="25" onChange={(e) => setDisplayName(e.target.value)}></input></td>
                        <td><input type="text" size="6" onChange={(e) => setBaseSymbol(e.target.value)}></input></td>
                        <td><input type="text" size="6" onChange={(e) => setTradeSymbol(e.target.value)}></input></td>
                        <td><input type="text" size="15" defaultValue="Mean Reversion" onChange={(e) => setType(e.target.value)}></input></td>
                        <td><input type='checkbox' checked={short} onClick={() => setShort(!short)} /></td>
                        <td>{shouldAllowEdits() ? <button class="btn btn-secondary" onClick={(e) => handleSubmit()}>Create</button> : null} {JSON.stringify(result)}</td>
                    </tr>
                </Table>
                :
                <div class="empty-page" >Not allowed</div>
            }
            <br></br>

        </div >);
}

export default CreateAlgo;