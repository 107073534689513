import React, { useState } from 'react';
import { Table } from 'react-bootstrap'
import './index.css'

function ForceTrade(props) {

    const [algo, setAlgo] = useState('testalgo')
    const [baseSymbol, setBaseSymbol] = useState('SPY')
    const [action, setAction] = useState('buy')
    const [result, setResult] = useState('')

    function shouldAllowEdits() {
        if (props.role === 3) return true;
        else return false;
    }

    async function handleSubmit() {
        console.log("handleSubmit");
        const url = "/api/forceTrade?algo=" + algo + "&baseSymbol=" + baseSymbol + "&action=" + action;
        console.log(url);
        const r = await (await fetch(url)).json();
        setResult(r);
    }

    return (
        <div>
            {shouldAllowEdits() ?
                <Table border="1">
                    <tr>
                        <th>Algo</th>
                        <th>Base Symbol</th>
                        <th>Action</th>
                    </tr>
                    <tr>
                        <td><input type="text" size="20" defaultValue="testalgo" onChange={(e) => setAlgo(e.target.value)}></input></td>
                        <td><input type="text" size="6" defaultValue="SPY" onChange={(e) => setBaseSymbol(e.target.value)}></input></td>
                        <td><input type="text" size="7" defaultValue="buy" onChange={(e) => setAction(e.target.value)}></input></td>
                        <td>{shouldAllowEdits() ? <button class="btn btn-secondary" onClick={(e) => handleSubmit()}>Place Trade</button> : null} {JSON.stringify(result)}</td>
                    </tr>
                </Table>
                :
                <div class="empty-page" >Not allowed</div>
            }
            <br></br>

        </div >);
}

export default ForceTrade;