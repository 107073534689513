import React, { useState, useEffect } from 'react';
import RichTable from './components/richTable/RichTable.jsx'
import tableHelpers from './TableHelpers.js'
import { Chart } from "react-google-charts";

function QuoteDateAnalysis(props) {

    const [dateAnalysis, setDateAnalysis] = useState(null);
    const [tradesForDate, setTradesForDate] = useState(null);

    useEffect(() => {
        (async function () {

            setDateAnalysis(null);
            setTradesForDate(props.trades);

        })();
    }, [props.trades]);

    async function loadEntryAnalysis(symbol, trades) {
        if (trades && trades.length) {
            setDateAnalysis([]);
            let dates = "";
            trades.forEach(trade => {
                let date = new Date(trade.purchaseDate);
                dates += date.getUTCFullYear().toString() + "-" + (date.getUTCMonth() + 1).toString() + "-" + (date.getUTCDate()).toString() + ",";
            })

            console.log("dates", trades.length, dates);
            let url = "/api/quoteHistoryAnalysis?symbol=" + symbol + "&dates=" + dates
            const r = await (await fetch(url)).json();
            console.log("analysis", r.length, r);
            setDateAnalysis(r);
        }
    }


    async function loadExitAnalysis(symbol, trades) {
        if (trades && trades.length) {
            setDateAnalysis([]);
            let dates = "";
            trades.forEach(trade => {
                let date = new Date(trade.sellDate);
                dates += date.getUTCFullYear().toString() + "-" + (date.getUTCMonth() + 1).toString() + "-" + (date.getUTCDate()).toString() + ",";
            })

            let url = "/api/quoteHistoryAnalysis?symbol=" + symbol + "&dates=" + dates
            const r = await (await fetch(url)).json();
            setDateAnalysis(r);
        }
    }

    function getTradeAnalysis() {
        if (dateAnalysis && dateAnalysis.length) {
            let results = { count: 0, start: 0, oneDay: 0, oneDayWinners: 0, twoDay: 0, twoDayWinners: 0, threeDay: 0, threeDayWinners: 0, oneWeek: 0, oneWeekWinners: 0, twoWeek: 0, twoWeekWinners: 0, oneMonth: 0, oneMonthWinners: 0, oneDayPrior: 0, oneDayPriorWinners: 0, twoDayPrior: 0, twoDayPriorWinners: 0, oneWeekPrior: 0, oneWeekPriorWinners: 0, twoWeekPrior: 0, twoWeekPriorWinners: 0, oneMonthPrior: 0, oneMonthPriorWinners: 0 }

            dateAnalysis.forEach(trade => {
                let oneDayChange = (trade.oneDayClose - trade.close) / trade.close * 100;
                let twoDayChange = (trade.twoDaysClose - trade.close) / trade.close * 100;
                let threeDayChange = (trade.threeDaysClose - trade.close) / trade.close * 100;
                let oneWeekChange = (trade.oneWeekClose - trade.close) / trade.close * 100;
                let twoWeekChange = (trade.twoWeeksClose - trade.close) / trade.close * 100;
                let oneMonthChange = (trade.oneMonthClose - trade.close) / trade.close * 100;
                let oneDayPriorChange = (trade.oneDayPriorClose - trade.close) / trade.close * 100;
                let twoDayPriorChange = (trade.twoDaysPriorClose - trade.close) / trade.close * 100;
                let oneWeekPriorChange = (trade.oneWeekPriorClose - trade.close) / trade.close * 100;
                let twoWeekPriorChange = (trade.twoWeekPriorClose - trade.close) / trade.close * 100;
                let oneMonthPriorChange = (trade.oneMonthPriorClose - trade.close) / trade.close * 100;

                results.oneDay = ((results.oneDay * results.count) + oneDayChange) / (results.count + 1);
                results.twoDay = ((results.twoDay * results.count) + twoDayChange) / (results.count + 1);
                results.threeDay = ((results.threeDay * results.count) + threeDayChange) / (results.count + 1);
                results.oneWeek = ((results.oneWeek * results.count) + oneWeekChange) / (results.count + 1);
                results.twoWeek = ((results.twoWeek * results.count) + twoWeekChange) / (results.count + 1);
                results.oneMonth = ((results.oneMonth * results.count) + oneMonthChange) / (results.count + 1);
                results.oneDayPrior = ((results.oneDayPrior * results.count) + oneDayPriorChange) / (results.count + 1);
                results.twoDayPrior = ((results.twoDayPrior * results.count) + twoDayPriorChange) / (results.count + 1);
                results.oneWeekPrior = ((results.oneWeekPrior * results.count) + oneWeekPriorChange) / (results.count + 1);
                results.twoWeekPrior = ((results.twoWeekPrior * results.count) + twoWeekPriorChange) / (results.count + 1);
                results.oneMonthPrior = ((results.oneMonthPrior * results.count) + oneMonthPriorChange) / (results.count + 1);
                if (oneDayChange >= 0) ++results.oneDayWinners;
                if (twoDayChange >= 0) ++results.twoDayWinners;
                if (threeDayChange >= 0) ++results.threeDayWinners;
                if (oneWeekChange >= 0) ++results.oneWeekWinners;
                if (twoWeekChange >= 0) ++results.twoWeekWinners;
                if (oneMonthChange >= 0) ++results.oneMonthWinners;
                if (oneDayPriorChange >= 0) ++results.oneDayPriorWinners;
                if (twoDayPriorChange >= 0) ++results.twoDayPriorWinners;
                if (oneWeekPriorChange >= 0) ++results.oneWeekPriorWinners;
                if (twoWeekPriorChange >= 0) ++results.twoWeekPriorWinners;
                if (oneMonthPriorChange >= 0) ++results.oneMonthPriorWinners;

                results.count++;
            })

            results.oneDayPriorWinPercent = results.oneDayPriorWinners / results.count * 100;
            results.twoDayPriorWinPercent = results.twoDayPriorWinners / results.count * 100;
            results.threeDayPriorWinPercent = results.threeDayPriorWinners / results.count * 100;
            results.oneWeekPriorWinPercent = results.oneWeekPriorWinners / results.count * 100;
            results.twoWeekPriorWinPercent = results.twoWeekPriorWinners / results.count * 100;
            results.oneMonthPriorWinPercent = results.oneMonthPriorWinners / results.count * 100;
            results.oneDayWinPercent = results.oneDayWinners / results.count * 100;
            results.twoDayWinPercent = results.twoDayWinners / results.count * 100;
            results.threeDayWinPercent = results.threeDayWinners / results.count * 100;
            results.oneWeekWinPercent = results.oneWeekWinners / results.count * 100;
            results.twoWeekWinPercent = results.twoWeekWinners / results.count * 100;
            results.oneMonthWinPercent = results.oneMonthWinners / results.count * 100;

            return [results]
        } else {
            return [];
        }
    }

    function getChartData() {
        let data = [['Days', 'Return', { role: "annotation" }]];

        let analysis = getTradeAnalysis();
        if (analysis.length) {
            let stats = analysis[0];
            data.push([-30, stats.oneMonthPrior / 100, stats.oneMonthPriorWinPercent.toFixed(0)])
            data.push([-14, stats.twoWeekPrior / 100, stats.twoWeekPriorWinPercent.toFixed(0)])
            data.push([-7, stats.oneWeekPrior / 100, stats.oneWeekPriorWinPercent.toFixed(0)])
            data.push([-2, stats.twoDayPrior / 100, stats.twoDayPriorWinPercent.toFixed(0)])
            data.push([-1, stats.oneDayPrior / 100, stats.oneDayPriorWinPercent.toFixed(0)])
            data.push([0, 0, 0]);
            data.push([1, stats.oneDay / 100, stats.oneDayWinPercent.toFixed(0)])
            data.push([2, stats.twoDay / 100, stats.twoDayWinPercent.toFixed(0)])
            data.push([3, stats.threeDay / 100, stats.threeDayWinPercent.toFixed(0)])
            data.push([7, stats.oneWeek / 100, stats.oneWeekWinPercent.toFixed(0)])
            data.push([14, stats.twoWeek / 100, stats.twoWeekWinPercent.toFixed(0)])
            data.push([30, stats.oneMonth / 100, stats.oneMonthWinPercent.toFixed(0)])
        }

        console.log("chartData", data);
        return data;
    }

    function getMinMax(chartData) {
        let min = null;
        let max = null;
        for (let i = 1; i < chartData.length; i++) {
            if (min === null || chartData[i][1] < min) min = chartData[i][1];
            if (max === null || chartData[i][1] > max) max = chartData[i][1];
        }
        return { min: min, max: max }
    }

    let chartData = getChartData();
    let minMax = getMinMax(chartData);

    return (
        <div>
            &nbsp;&nbsp;&nbsp;<button onClick={() => loadEntryAnalysis(props.symbol, tradesForDate)}>Entry Analysis</button>
            &nbsp;&nbsp;&nbsp;<button onClick={() => loadExitAnalysis(props.symbol, tradesForDate)}>Exit Analysis</button>
            <br></br>
            {dateAnalysis ?
                <>
                    {chartData && chartData.length > 1 ?
                        <Chart
                            chartType="AreaChart"
                            data={chartData}
                            width={props.width ? props.width : "100%"}
                            height={"100px"}
                            options={{
                                legend: 'none',
                                vAxis: {
                                    viewWindow: {
                                        max: minMax.max,
                                        min: minMax.min
                                    },
                                    format: 'percent'
                                }
                            }}
                            chartPackages={["corechart", "controls"]}
                        />
                        :
                        null}
                    <RichTable noDataMessage={"Loading"} data={getTradeAnalysis()} className="table-striped table-condensed" mappers={[
                        { title: 'Count', field: 'count' },
                        { title: '1M Prior', field: 'oneMonthPrior', formatter: tableHelpers.percentFormatter },
                        { title: '2W Prior', field: 'twoWeekPrior', formatter: tableHelpers.percentFormatter },
                        { title: '1W Prior', field: 'oneWeekPrior', formatter: tableHelpers.percentFormatter },
                        { title: '2D Prior', field: 'twoDayPrior', formatter: tableHelpers.percentFormatter },
                        { title: '1D Prior', field: 'oneDayPrior', formatter: tableHelpers.percentFormatter },
                        { title: '1D After', field: 'oneDay', formatter: tableHelpers.percentFormatter },
                        { title: '2D After', field: 'twoDay', formatter: tableHelpers.percentFormatter },
                        { title: '3D After', field: 'threeDay', formatter: tableHelpers.percentFormatter },
                        { title: '1W After', field: 'oneWeek', formatter: tableHelpers.percentFormatter },
                        { title: '1W Win%', field: 'oneWeekWinPercent', formatter: tableHelpers.percentFormatter },
                        { title: '2W After', field: 'twoWeek', formatter: tableHelpers.percentFormatter },
                        { title: '2W Win%', field: 'twoWeekWinPercent', formatter: tableHelpers.percentFormatter },
                        { title: '1M After', field: 'oneMonth', formatter: tableHelpers.percentFormatter },
                        { title: '1M Win%', field: 'oneMonthWinPercent', formatter: tableHelpers.percentFormatter },
                    ]} />
                </>
                :
                <>
                </>
            }

        </div >);
}

export default QuoteDateAnalysis;