import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap'
import './index.css'
import { Link } from 'react-router-dom'
import tableHelpers from './TableHelpers.js'
import { Chart } from "react-google-charts";
import Tooltip from './Tooltip.js'
import RichTable from './components/richTable/RichTable.jsx'
import { createDateSorter } from './components/richTable/sorters.js'

function AlertEvaluationResults(props) {

    const [performanceArray, setPerformanceArray] = useState([])
    const [performance, setPerformance] = useState({})
    const [showDrawdown, setShowDrawdown] = useState(false);

    useEffect(() => {
        (async function () {
            if (props.performance)
                setPerformance(props.performance);
            if (props.performanceArray)
                setPerformanceArray(props.performanceArray);
        })();
    }, [props.alertId, props.performance, props.performanceArray]);

    function getPerformanceArray() {
        return performanceArray.map(performance => {
            return {
                id: performance.id,
                winningPercentage: performance.winningPercentage,
                avgProfit: performance.avgProfit,
                tradeCount: performance.tradeCount,
                avgDurationInBars: performance.avgDurationInBars,
                netProfit: performance.netProfit,
                avgWin: performance.avgWin,
                avgLoss: performance.avgLoss,
                maxLoss: performance.maxLoss,
                calculationTime: performance.calculationTime,
                config: performance.config,
                currentBarStatus: performance.currentBarStatus,
                currentBarData: performance.currentBarData,
                maxTradeDrawdown: performance.maxTradeDrawdown,
                avgTradeMaxDrawdown: performance.avgTradeMaxDrawdown,
                maxDrawdown: performance.maxDrawdown,
                rewardRiskRatio: performance.netProfit / performance.maxDrawdown,
                metrics: performance
            }
        })
    }

    function getMostRecentEvaluation() {
        return [{
            id: "Last",
            winningPercentage: performance.winningPercentage,
            avgProfit: performance.avgProfit,
            tradeCount: performance.tradeCount,
            avgDurationInBars: performance.avgDurationInBars,
            netProfit: performance.netProfit,
            avgWin: performance.avgWin,
            avgLoss: performance.avgLoss,
            maxLoss: performance.maxLoss,
            calculationTime: performance.calculationTime,
            rewardRiskRatio: performance.netProfit / performance.maxDrawdown,
            config: performance.config,
            currentBarStatus: performance.currentBarStatus,
            currentBarData: performance.currentBarData,
            maxTradeDrawdown: performance.maxTradeDrawdown,
            avgTradeMaxDrawdown: performance.avgTradeMaxDrawdown,
            maxDrawdown: performance.maxDrawdown,
            metrics: performance
        }]
    }

    function getChartData() {
        if (performance && performance.trades && performance.trades.length) {
            let ret = [];
            if (showDrawdown)
                ret.push(["Trade", "Trade Max Drawdown"])
            else
                ret.push(["Trade", "Trade Profit"])
            let counter = 0;
            let max = null;
            let min = 0;
            performance.trades.forEach(t => {
                if (t.sellDate) {
                    ++counter;
                    let value = t.profitPercentage / 100;
                    if (showDrawdown)
                        value = t.maxDrawdown / 100;
                    ret.push([counter, value])

                    if (max === null || value > max)
                        max = value;
                    if (value < min)
                        min = value;
                }
            })
            return [ret, min, max];
        } else {
            return null;
        }
    }

    function getTrades() {
        if (performance && performance.trades && performance.trades.length) {
            let id = 0;
            return performance.trades.map(t => {
                return {
                    id: ++id,
                    purchaseDate: t.purchaseDate,
                    purchasePrice: t.purchasePrice,
                    sellDate: t.sellDate,
                    sellPrice: t.sellPrice,
                    profitPercentage: t.profitPercentage
                }
            })
        } else {
            return [];
        }
    }

    function getRealizedProfitChartData() {
        if (performance && performance.trades && performance.trades.length) {
            let ret = [];
            if (showDrawdown)
                ret.push(["Date", "Realized Drawdown"])
            else
                ret.push(["Date", "Realized Profit"])
            let net = 0.0;
            let min = null;
            let max = null;
            let maxProfit = null;
            let netProfit = 0.0;
            performance.trades.forEach(t => {
                if (t.sellDate) {
                    netProfit += t.profitPercentage;
                    if (maxProfit === null || netProfit > maxProfit)
                        maxProfit = netProfit;
                    if (showDrawdown) {
                        net = maxProfit - netProfit;
                    } else {
                        net = netProfit;
                    }
                    let sellDate = new Date(t.sellDate);
                    let date = (sellDate.getUTCMonth() + 1) + "/" + sellDate.getUTCDate() + "/" + (sellDate.getUTCFullYear() - 2000);
                    ret.push([date, net / 100])
                    if (max === null || net > max) max = net;
                    if (min === null || net < min) min = net;
                }
            })
            return [ret, min / 100, max / 100];
        } else {
            return null;
        }
    }

    function updateCharts(row) {
        let id = row.id;
        console.log("Updating chart to " + id);
        console.log("Searching " + performanceArray.length + " performance results");
        const perf = performanceArray.find(p => p.id === id);
        if (perf)
            setPerformance(perf);
    }

    function configPopupFormatter(cell, row) {
        return <Tooltip text={"Version " + cell} json={row.config} />
    }

    function metricsFormatter(cell, row) {
        let metrics = JSON.parse(JSON.stringify(row.metrics))
        metrics.trades = undefined;
        metrics.config = undefined;
        metrics.currentBarData = undefined;
        return <Tooltip text={"Metrics"} json={metrics} position="left center" />
    }

    function statusPopupFormatter(cell, row) {
        return <Tooltip text={cell} json={row.currentBarData} position="left center" />
    }

    let profitChartData = getChartData();
    let profitChartOptions = profitChartData ? {
        legend: { position: 'none' },
        vAxis: {
            format: '#%',
            viewWindow: {
                max: profitChartData[2] * 1.1,
                min: profitChartData[1] * 1.1
            },
        },
    } : null;

    let realizedProfitChartData = getRealizedProfitChartData();
    let realizedProfitChartOptions = realizedProfitChartData ? {
        legend: { position: 'none' },
        vAxis: {
            format: '#%',
            viewWindow: {
                max: realizedProfitChartData[2],
                min: showDrawdown ? 0 : realizedProfitChartData[1] - 0.05
            }
        }
    } : null;

    const resultsMapper = [
        { title: 'Config', field: 'id', formatter: configPopupFormatter, sorters: true },
        { title: 'Count', field: 'tradeCount', formatter: tableHelpers.intFormatter, sorters: true },
        { title: 'Avg Duration', field: 'avgDurationInBars', formatter: tableHelpers.intFormatter, hidden: props.mobileView, sorters: true },
        { title: 'Win %', field: 'winningPercentage', formatter: tableHelpers.percentFormatter, sorters: true },
        { title: 'Avg P/L', field: 'avgProfit', formatter: tableHelpers.percentFormatter, sorters: true },
        { title: 'Net Profit', field: 'netProfit', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
        { title: 'Avg Win', field: 'avgWin', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
        { title: 'Avg Loss', field: 'avgLoss', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
        { title: 'Max Loss', field: 'maxLoss', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
        { title: 'Max Drawdown', field: 'maxDrawdown', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
        { title: 'Max Trade DD', field: 'maxTradeDrawdown', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
        { title: 'Avg Max Trade DD', field: 'avgTradeMaxDrawdown', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
        { title: 'Reward:Risk', field: 'rewardRiskRatio', formatter: tableHelpers.decimalFormatter, hidden: props.mobileView, sorters: true },
        { title: 'Current Bar', field: 'currentBarStatus', formatter: statusPopupFormatter, sorters: true },
        { title: 'All Metrics', field: 'allMetrics', formatter: metricsFormatter, hidden: props.mobileView },
    ]

    const tradesMapper = [
        { title: 'Id', field: 'id', formatter: tableHelpers.intFormatter },
        { title: 'Purch Date', field: 'purchaseDate', sorters: createDateSorter('purchaseDate'), formatter: tableHelpers.dateFormmatter },
        { title: 'Purch Price', field: 'purchasePrice', sorters: true, formatter: tableHelpers.moneyFormatter },
        { title: 'Sell Date', field: 'sellDate', sorters: createDateSorter('sellDate'), formatter: tableHelpers.dateFormmatter },
        { title: 'Sell Price', field: 'sellPrice', sorters: true, formatter: tableHelpers.moneyFormatter },
        { title: 'P/L', field: 'profitPercentage', sorters: true, formatter: tableHelpers.percentFormatter },
    ]

    return (
        <div>
            <br></br>
            <RichTable data={getMostRecentEvaluation()} mappers={resultsMapper} className="table-striped table-hover table-condensed" />

            {
                props.performance && props.performance.trades && props.performance.trades.length && profitChartData && realizedProfitChartData ?
                    <center>
                        <Link onClick={() => setShowDrawdown(!showDrawdown)}>{showDrawdown ? "Show Profit" : "Show Drawdown"}</Link>
                        <Table>
                            <tr>
                                <td width="50%">
                                    <Chart
                                        chartType="ColumnChart"
                                        data={profitChartData[0]}
                                        width={"100%"}
                                        options={profitChartOptions}
                                        chartPackages={["corechart", "controls"]}
                                    />
                                </td>
                                <td>
                                    <Chart
                                        chartType="AreaChart"
                                        data={realizedProfitChartData[0]}
                                        width={"100%"}
                                        options={realizedProfitChartOptions}
                                        chartPackages={["corechart", "controls"]}
                                    />
                                </td>
                            </tr>
                        </Table>
                    </center>
                    :
                    null
            }
            <h4>History</h4>
            <RichTable data={getPerformanceArray()} onRowClickHandler={updateCharts} mappers={resultsMapper} className="table-striped table-hover table-condensed" />

            <br></br>

            {
                props.shouldShowTrades ?
                    <>
                        <RichTable pageSize={10} data={getTrades()} mappers={tradesMapper} className="table-striped table-hover table-condensed" />
                    </>
                    :
                    null
            }

        </div >);
}

export default AlertEvaluationResults;