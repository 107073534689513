import React, { useState, useEffect } from 'react';
import RichTable from './components/richTable/RichTable.jsx'
import { createDateSorter, createDefaultSorter } from './components/richTable/sorters.js'
import tableHelpers from './TableHelpers.js'
import Tooltip from './Tooltip.js'
import StickySettings from './StickySettings.js'
import { Link } from 'react-router-dom'
import AlertSettings from './AlertSettings.js';
import Dropdown from 'react-bootstrap/Dropdown';

function TradingStrategies(props) {
    const [data, setData] = useState([]);
    const [algos, setAlgos] = useState([]);
    const [symbol, setSymbol] = useState('Select symbol')
    const [algoName, setAlgoName] = useState('')
    const [timeframe, setTimeframe] = useState(0)
    const [result, setResult] = useState(null)
    const [updates, setUpdates] = useState(0);
    const [selectedStrategyId, setSelectedStrategyId] = useState(0);
    const [triggeredAlerts, setTriggeredAlerts] = useState([])
    const [shouldFilterPlan, setShouldFilterPlan] = useState(StickySettings.getBool('alertsFilterByPlan'));
    const [shouldFilterActiveOnly, setShouldFilterActiveOnly] = useState(StickySettings.getBool('alertsFilterByActive'));
    const [shouldFilterUnassignedOnly, setShouldFilterUnassignedOnly] = useState(StickySettings.getBool('alertsFilterByUnassignedOnly'))
    const [planAlgos, setPlanAlgos] = useState([])
    const [isEvaluating, setIsEvaluating] = useState(false)
    const [view, setView] = useState('standard')
    const [performance, setPerformance] = useState({});
    const [oosPerformance, setOosPerformance] = useState({});
    const [monteCarloPerformance, setMonteCarloPerformance] = useState({});

    useEffect(() => {
        (async function () {

            const algoText = await (await fetch(`/api/algos?planId=` + props.planId)).json();
            setPlanAlgos(algoText);

            const text = await (await fetch(`/api/strategyAlerts`)).json();
            console.log("strategy alerts", text);
            setData(text);

            const algos = await (await fetch(`/api/algoChildren`)).json();
            setAlgos(algos);
        })();
    }, [updates, props.planId]);

    function getFilterAlerts() {
        let alerts = data;
        if (shouldFilterActiveOnly)
            alerts = alerts.filter(a => a.paused === 0);
        if (shouldFilterPlan) {
            alerts = alerts.filter(a => {
                return planAlgos.find(algo => algo.symbol === a.symbol && algo.name === a.algo_name) !== undefined
            })
        }
        if (shouldFilterUnassignedOnly) {
            alerts = alerts.filter(a => {
                return algos.find(algo => algo.symbol === a.symbol && algo.name === a.algo_name) === undefined
            })
        }
        return alerts;
    }

    function getAlerts() {

        const alerts = getFilterAlerts();

        return alerts.map(alert => {
            let alertStatus = triggeredAlerts.find(a => a.alertId === alert.idtrading_strategy_alerts);
            return {
                id: alert.idtrading_strategy_alerts,
                strategyId: alert.strategyId,
                strategyName: alert.name,
                algoName: alert.algo_name,
                symbol: alert.symbol,
                timeframe: alert.timeframe,
                config: alert.config,
                create_date: alert.create_date,
                paused: alert.paused,
                status: alert.paused ? "Paused" : "Active",
                last_triggered: alert.last_triggered,
                currentBarStatus: alertStatus ? alertStatus.status : null,
                currentPosition: alertStatus ? alertStatus.position : null,
                currentBarData: alertStatus ? alertStatus.currentBarData : null
            }
        });
    }

    function getAlertsPerformance() {

        console.log("getAlertsPerformance", oosPerformance);

        const alerts = getFilterAlerts();

        return alerts.map(alert => {
            let perf = performance[alert.idtrading_strategy_alerts];
            let oosPerf = oosPerformance[alert.idtrading_strategy_alerts]
            let monteCarloPerf = monteCarloPerformance[alert.idtrading_strategy_alerts];
            return {
                id: alert.idtrading_strategy_alerts,
                strategyId: alert.strategyId,
                strategyName: alert.name,
                algoName: alert.algo_name,
                symbol: alert.symbol,
                timeframe: alert.timeframe,
                config: alert.config,
                winRate: perf ? perf.winningPercentage : null,
                avgProfit: perf ? perf.avgProfit : null,
                maxDrawdown: perf ? perf.maxDrawdown : null,
                oosWinRate: oosPerf ? oosPerf.winningPercentage : null,
                oosAvgProfit: oosPerf ? oosPerf.avgProfit : null,
                oosMaxDrawdown: oosPerf ? oosPerf.maxDrawdown : null,
                monteCarloWinRate: monteCarloPerf ? monteCarloPerf.winningPercentage : null,
                monteCarloAvgProfit: monteCarloPerf ? monteCarloPerf.avgProfit : null,
                monteCarloMaxDrawdown: monteCarloPerf ? monteCarloPerf.maxDrawdown : null
            }
        });
    }

    function shouldAllowEdits() {
        if (props.role === 3) return true;
        else return false;
    }

    async function handleSubmit() {
        setResult('Creating Alert');
        const url = "/api/strategyAlertCreate?strategyId=" + selectedStrategyId + "&symbol=" + symbol + "&timeframe=" + timeframe + "&algo=" + algoName;
        console.log(url);
        const r = await (await fetch(url, { method: 'POST' })).json();
        setResult(r);
        setUpdates(updates + 1);
    }

    async function handleDeleteAlert(alertId) {
        console.log("Delete alert", alertId);
        setResult('Deleting alert...');
        const url = "/api/strategyAlertDelete?alertId=" + alertId;
        const r = await (await fetch(url, { method: 'POST' })).json();
        setResult(r);
        setUpdates(updates + 1);
    }

    async function handleResetAlertTriggered(alertId) {
        console.log("Resetting alert triggered", alertId);
        setResult('Resetting alert...');
        const url = "/api/strategyAlertUpdateTriggered?alertId=" + alertId + "&isTriggered=false";
        const r = await (await fetch(url, { method: 'POST' })).json();
        setResult(r);
        setUpdates(updates + 1);
    }

    async function handleConfigureAlert(alertId) {
        if (props.onAlertSelected)
            props.onAlertSelected(alertId);
    }

    async function handleCheckStatus(alertId) {
        console.log("handleCheckStatus", alertId);
        const url = "/api/strategyAlertEvaluator?alertId=" + alertId + "&currentBarOnly=1"
        const r = await (await fetch(url, {
            method: 'POST',
        })).json();
        console.log(r);
        let alerts = triggeredAlerts.map(a => a);
        let alert = {
            alertId: alertId,
            status: r.currentBarStatus,
            currentBarData: r.currentBarData
        }
        console.log("Pushing alert", alert);
        alerts.push(alert);
        setTriggeredAlerts(alerts);
    }

    async function handleEvaluate(alertId) {
        console.log("handleEvaluate", alertId);
        let url = "/api/strategyAlertEvaluator?alertId=" + alertId
        const r = await (await fetch(url, {
            method: 'POST',
        })).json();
        return r;
    }

    async function handleOOSEvaluate(alertId) {
        console.log("handleOOSEvaluate", alertId);
        let url = "/api/strategyAlertEvaluator?alertId=" + alertId
        url += "&startDate=2011-1-1&endDate=2014-1-1";
        const r = await (await fetch(url, {
            method: 'POST',
        })).json();
        return r;
    }

    async function handleMonteCarloEvaluate(alertId) {
        let url = "/api/strategyAlertMonteCarlo?alertId=" + alertId + "&count=20";
        const r = await (await fetch(url, {
            method: 'POST',
        })).json();
        return r;
    }

    function adminButtonsFormatter(cell, row) {
        return (
            <>
                {shouldAllowEdits() && row.last_triggered ? <><Link onClick={() => handleResetAlertTriggered(row.id)}>Reset Triggered</Link>&nbsp;&nbsp;</> : null}
                {shouldAllowEdits() ? <Link onClick={() => handleDeleteAlert(row.id)}>Delete</Link> : null}
            </>
        )
    }

    function configureButtonFormatter(cell, row) {
        return (
            <>
                <Link className="bg-transparent" to={"/configureAlert"} onClick={() => handleConfigureAlert(row.id)}>Update</Link>
            </>
        )
    }

    function statusButtonFormatter(cell, row) {
        if (row.currentBarStatus === null)
            return (
                <>
                    <Link className="bg-transparent" onClick={() => handleCheckStatus(row.id)}>Check</Link>
                </>
            )
        else {
            return <Tooltip text={row.currentBarStatus} json={row.currentBarData} position={"left center"} />
        }
    }

    function configPopupFormatter(cell, row) {
        return <Tooltip text={row.strategyName} json={row.config} />
    }

    function onAlgoClicked(algoId) {
        if (props.onAlgoSelected)
            props.onAlgoSelected(algoId);
    }

    function algoPopupFormatter(cell, row) {

        let childrenDisplay = {};
        const children = algos.filter(a => a.name === row.algoName && a.symbol === row.symbol);
        let displayName = cell;
        let algoId = null;
        if (children) {
            children.forEach(c => {
                displayName = c.display_name;
                algoId = c.idalgos;
                childrenDisplay[c.plan_name] = c.percentage + "%";
            })
        }
        return (
            <>
                <Tooltip json={childrenDisplay} text={displayName} />
                {algoId ? <>&nbsp;&nbsp;<Link className="small-text" to={"/algo"} onClick={() => onAlgoClicked(algoId)}>(Details)</Link></> : null}
            </>
        )
    }

    async function loadPerformance(alertId) {
        let perf = await handleEvaluate(alertId);
        if (perf) {
            let orig = JSON.parse(JSON.stringify(performance));
            orig[alertId] = perf;
            setPerformance(orig);
        }

        let oosEvaluate = await handleOOSEvaluate(alertId);
        if (oosEvaluate) {
            let orig = JSON.parse(JSON.stringify(oosPerformance));
            orig[alertId] = oosEvaluate;
            setOosPerformance(orig);
        }

        let monteCarloEvaluate = await handleMonteCarloEvaluate(alertId);
        if (monteCarloEvaluate) {
            let orig = JSON.parse(JSON.stringify(monteCarloPerformance));
            orig[alertId] = monteCarloEvaluate;
            setMonteCarloPerformance(orig);
        }
    }

    function loadPerformanceButtonFormatter(cell, row) {
        return <Link onClick={() => loadPerformance(row.id)}>Load</Link>
    }

    async function handleEvaluateAllAlerts() {
        setIsEvaluating(true);
        console.log("handleEvaluateAllAlerts");
        const url = "/api/strategyAlertAllEvaluator"
        const r = await (await fetch(url, { method: 'POST' })).json();
        setTriggeredAlerts(r);
        setIsEvaluating(false);
    }

    async function handleDailyEvaluateAllAlerts() {
        setIsEvaluating(true);
        console.log("handleEvaluateAllAlerts");
        const url = "/api/dailyStrategyAlertEvaluator"
        const r = await (await fetch(url, { method: 'POST' })).json();
        setTriggeredAlerts(r);
        setIsEvaluating(false);
    }

    async function handleEvaluateFilteredAlerts() {
        console.log("handleEvaluateFilteredAlerts");
        setIsEvaluating(true);
        let alerts = getFilterAlerts();
        let alertIds = alerts.map(a => a.idtrading_strategy_alerts)
        const url = "/api/strategyAlertAllEvaluator?alertIds=" + alertIds
        console.log(url);
        const r = await (await fetch(url, { method: 'POST' })).json();
        setTriggeredAlerts(r);
        setIsEvaluating(false);
    }

    function onPlanFilterSelected() {
        console.log("onPlanFilterSelected");
        StickySettings.setBool('alertsFilterByPlan', !shouldFilterPlan);
        setShouldFilterPlan(!shouldFilterPlan);
    }

    function onUnassignedFilterSelected() {
        StickySettings.setBool('alertsFilterByUnassignedOnly', !shouldFilterUnassignedOnly);
        setShouldFilterUnassignedOnly(!shouldFilterUnassignedOnly);
    }

    function onActiveFilterSelected() {
        console.log("onActiveFilterSelected");
        StickySettings.setBool('alertsFilterByActive', !shouldFilterActiveOnly);
        setShouldFilterActiveOnly(!shouldFilterActiveOnly);
    }

    function handleCreateAlertUpdate(strategyId, symbol, timeframe, name) {
        console.log(strategyId, symbol, timeframe, name);
        setSelectedStrategyId(strategyId);
        setSymbol(symbol);
        setTimeframe(timeframe);
        setAlgoName(name);
    }

    const alertsTableMappers = [
        { title: 'Algo Name', field: 'algoName', formatter: algoPopupFormatter, sorters: [createDefaultSorter('algoName'), createDefaultSorter('symbol')], filter: 'text' },
        { title: 'Symbol', field: 'symbol', sorters: true, filter: 'text' },
        { title: 'Strategy', field: 'strategyName', formatter: configPopupFormatter, sorters: true, filter: 'text', hidden: props.mobile },
        { title: 'Current Bar', field: 'currentBarStatus', formatter: statusButtonFormatter, sorters: true },
        { title: 'Configure', field: 'id', formatter: configureButtonFormatter },
        { title: 'Status', field: 'status', sorters: true, hidden: props.mobileView },
        { title: 'Last Triggered', field: 'last_triggered', formatter: tableHelpers.dateFormmatter, sorters: createDateSorter('last_triggered'), hidden: props.mobileView },
        { title: 'Delete', field: 'id', formatter: adminButtonsFormatter, sorters: true, hidden: !shouldAllowEdits() || props.mobileView }
    ]

    const alertsPerformanceMappers = [
        { title: 'Algo Name', field: 'algoName', formatter: algoPopupFormatter, sorters: [createDefaultSorter('algoName'), createDefaultSorter('symbol')], filter: 'text' },
        { title: 'Symbol', field: 'symbol', sorters: true, filter: 'text' },
        { title: 'Configure', field: 'id', formatter: configureButtonFormatter },
        { title: 'Load', field: 'winRate', formatter: loadPerformanceButtonFormatter },
        { title: 'Win %', field: 'winRate', formatter: tableHelpers.percentFormatter },
        { title: 'OOS Win %', field: 'oosWinRate', formatter: tableHelpers.percentFormatter },
        { title: 'MC Win %', field: 'monteCarloWinRate', formatter: tableHelpers.percentFormatter },
        { title: 'Avg Profit', field: 'avgProfit', formatter: tableHelpers.percentFormatter },
        { title: 'OOS Avg Profit', field: 'oosAvgProfit', formatter: tableHelpers.percentFormatter },
        { title: 'MC Avg Profit', field: 'monteCarloAvgProfit', formatter: tableHelpers.percentFormatter },
        { title: 'Max DD', field: 'maxDrawdown', formatter: tableHelpers.percentFormatter },
        { title: 'OOS Max DD', field: 'oosMaxDrawdown', formatter: tableHelpers.percentFormatter },
        { title: 'MC Max DD', field: 'monteCarloMaxDrawdown', formatter: tableHelpers.percentFormatter },
    ]

    return (
        <>
            <>
                <h3>Create Alert</h3>
                <AlertSettings onAlertUpdated={handleCreateAlertUpdate} /><br></br>
                {shouldAllowEdits() ? <button class="btn btn-secondary" onClick={(e) => handleSubmit()}>Create Alert</button> : null}
                &nbsp;&nbsp;&nbsp;<Link to="/alertSandbox">Go to Sandbox</Link>
            </>

            {
                result ?
                    <>
                        <hr></hr>
                        <h3>Result</h3>

                        {JSON.stringify(result, null, '\t')}
                    </>
                    :
                    null
            }

            <hr></hr>
            <h3>Alerts</h3>
            <div className="sameline">
                <input type='checkbox' checked={shouldFilterPlan} onClick={() => onPlanFilterSelected()} />Plan only
                &nbsp;&nbsp;&nbsp;&nbsp;<input type='checkbox' checked={shouldFilterActiveOnly} onClick={() => onActiveFilterSelected()} />Active only
                &nbsp;&nbsp;&nbsp;&nbsp;<input type='checkbox' checked={shouldFilterUnassignedOnly} onClick={() => onUnassignedFilterSelected()} />Unassigned only
                &nbsp;&nbsp;&nbsp;&nbsp;<button disabled={isEvaluating} class="btn btn-primary" onClick={() => handleEvaluateFilteredAlerts()}>{isEvaluating ? "Please wait..." : "Evaluate Filtered"}</button>
                &nbsp;&nbsp;View:&nbsp;
                <Dropdown onSelect={(e) => setView(e)}>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        {view}
                    </Dropdown.Toggle>

                    <Dropdown.Menu  >
                        <Dropdown.Item as='viewId' eventKey={'standard'} value={'standard'}>Standard</Dropdown.Item>
                        <Dropdown.Item as='viewId' eventKey={'performance'} value={'performance'}>Performance</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <br></br>

            {view === 'performance' ?
                <RichTable data={getAlertsPerformance()} mappers={alertsPerformanceMappers} className="table-striped table-hover table-condensed" />
                :
                <RichTable data={getAlerts()} mappers={alertsTableMappers} className="table-striped table-hover table-condensed" />
            }

            <br></br>
            <button class="btn btn-primary" disabled={isEvaluating} onClick={() => handleEvaluateAllAlerts()}>{isEvaluating ? "Please Wait..." : "Evaluate All"}</button>
            <br></br>
            <br></br>
            {shouldAllowEdits() ?
                <>
                    <button class="btn btn-danger" disabled={isEvaluating} onClick={() => handleDailyEvaluateAllAlerts()}>{isEvaluating ? "Please Wait..." : "Generate Daily Trades"}</button>
                    <br></br>
                    <br></br>
                </>
                :
                null
            }
        </>
    );
}

export default TradingStrategies;