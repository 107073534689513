import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import tableHelpers from './TableHelpers.js'
import RichTable from './components/richTable/RichTable.jsx'
import { createDateSorter } from './components/richTable/sorters.js'

function AllocationChanges(props) {
    const [data, setData] = useState([]);

    useEffect(() => {
        (function () {
            fetch(`/api/allocationChanges/?planId=` + props.planId)
                .then(res => res.json())
                .then(text => {
                    let mergedTrades = mergeTrades(text);
                    setData(mergedTrades);
                });
        })();
    }, [props.planId]);

    function isToday(date) {
        let now = new Date();
        return now.getDate() === date.getDate() && now.getMonth() === date.getMonth() && now.getFullYear() === date.getFullYear();
    }

    function getAction(change) {
        let retVal = "";
        if (change.action === 'buy') {
            retVal = "Added " + change.percentage + "%";
        } else {
            retVal = "Reduced " + change.percentage + "%";
        }
        return retVal;
    }

    function onAlgoClicked(algoId) {
        console.log("onAlgoClicked", algoId)
        if (props.onAlgoSelected)
            props.onAlgoSelected(algoId);
    }

    function mergeTrades(trades) {
        let merged = [];
        for (let i = 0; i < trades.length; i++) {
            let trade = { ...trades[i] };
            if (trade.merged === true)
                continue;

            trade.algos = [{
                display_name: trade.display_name,
                algoId: trade.algoId,
                algo_type: trade.algo_type,
                winning_percentage: trade.winning_percentage,
                avg_profit: trade.avg_profit,
                avg_duration: trade.avg_duration,
                allocation: trade.percentage
            }];

            for (let j = i + 1; j < trades.length; j++) {
                let otherTrade = trades[j];
                if (otherTrade.merged !== true && trade.date === otherTrade.date && trade.trade_symbol === otherTrade.trade_symbol && trade.action === otherTrade.action) {
                    console.log("Merging trades", trade, otherTrade);
                    otherTrade.merged = true;
                    trade.percentage += otherTrade.percentage;
                    trade.algos.push({
                        display_name: otherTrade.display_name,
                        algoId: otherTrade.algoId,
                        algo_type: otherTrade.algo_type,
                        winning_percentage: otherTrade.winning_percentage,
                        avg_profit: otherTrade.avg_profit,
                        avg_duration: otherTrade.avg_duration,
                        allocation: otherTrade.percentage,
                    });
                }
            }
            merged.push(trade);
        }
        return merged;
    }

    function getChanges() {
        return data.map(change => {
            return {
                date: change.date,
                trade_symbol: change.trade_symbol,
                display_name: change.display_name,
                algoId: change.algoId,
                action: getAction(change),
                close: change.close,
                shares: change.close > 0 ? (props.accountValue * change.percentage / 100 / change.close) : 0,
                algo_type: change.algo_type,
                winning_percentage: change.winning_percentage,
                avg_profit: change.avg_profit,
                avg_duration: change.avg_duration,
                algos: change.algos,
            }
        })
    }

    function algoLinksFormatter(cell, row) {
        return (
            <>
                {
                    row.algos.map(algo => {
                        return (<><Link className="bg-transparent" to={"/algo"} onClick={() => onAlgoClicked(algo.algoId)}>{algo.display_name}</Link>{renderNewLineIfNecessary(row.algos.length)}</>)
                    })
                }
            </>
        );
    }

    function renderNewLineIfNecessary(count) {
        return count > 1 ? (<><br></br><br></br></>) : null;
    }

    function algoTypeFormmatter(cell, row) {
        return (
            <>
                {
                    row.algos.map(algo => {
                        return (<>{algo.algo_type}{renderNewLineIfNecessary(row.algos.length)}</>)
                    })
                }
            </>
        );
    }

    function algoWinPercentageFormmatter(cell, row) {
        return (
            <>
                {
                    row.algos.map(algo => {
                        return algo.winning_percentage
                               ? (<>{algo.winning_percentage.toFixed(2)}%{renderNewLineIfNecessary(row.algos.length)}</>)
                               : null
                    })
                }
            </>
        );
    }

    function algoAvgProfitFormatter(cell, row) {
        return (
            <>
                {
                    row.algos.map(algo => {
                        return algo.avg_profit
                               ? (<>{algo.avg_profit.toFixed(2)}%{renderNewLineIfNecessary(row.algos.length)}</>)
                               : null
                    })
                }
            </>
        );
    }

    function algoAllocationFormatter(cell, row) {
        return (
            <>
                {
                    row.algos.map(algo => {
                        return (<>{algo.allocation}%{renderNewLineIfNecessary(row.algos.length)}</>)
                    })
                }
            </>
        );
    }

    function algoAvgDurationFormatter(cell, row) {
        return (
            <>
                {
                    row.algos.map(algo => {
                        return (<>{algo.avg_duration}{renderNewLineIfNecessary(row.algos.length)}</>)
                    })
                }
            </>
        );
    }
    function rowClassNameFormat(row) {
        // row is whole row object
        let d = new Date(row.date);
        d.setHours(d.getHours() + 8);
        if (isToday(d)) {
            return "tableRowBold";
        } else {
            return "tableRow"
        }
    }

    return (
        <>
            <br></br>
            <br></br>
            <>
                <center>
                    <div className='bold'>
                        Please visit <Link id="link" className="bg-transparent" to={"/newTrades"}>Today's Trades</Link> to get the trades for today.
                        <br></br>
                        This will ensure that long/short hedge trades take each other into account.
                    </div>
                </center>
            </>

            <br></br>
            <RichTable pageSize={10} rowClassName={rowClassNameFormat} data={getChanges()} className="table-striped table-hover table-condensed" mappers={[
                { title: 'Date', field: 'date', sorters: createDateSorter('date'), formatter: tableHelpers.dateFormmatter },
                { title: 'Symbol', field: 'trade_symbol', sorters: true },
                { title: 'Action', field: 'action', sorters: true },
                { title: 'Shares', field: 'shares', formatter: tableHelpers.intFormatter, sorters: true },
                { title: 'Price', field: 'close', formatter: tableHelpers.moneyFormatter, sorters: true, hidden: props.mobileView },
                { title: 'Algos', field: 'algos', formatter: algoLinksFormatter, hidden: props.mobileView },
                { title: 'Allocation', field: 'algos', formatter: algoAllocationFormatter, hidden: props.mobileView },
                { title: 'Trade Type', field: 'algos', formatter: algoTypeFormmatter, hidden: props.mobileView },
                { title: 'Win %', field: 'algos', formatter: algoWinPercentageFormmatter, hidden: props.mobileView },
                { title: 'Avg Profit', field: 'algos', formatter: algoAvgProfitFormatter, hidden: props.mobileView },
                { title: 'Avg Duration', field: 'algos', formatter: algoAvgDurationFormatter, hidden: props.mobileView },
            ]} />
            <br></br>
        </>
    );
}

export default AllocationChanges;