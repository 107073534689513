import React, { useState, useEffect } from 'react';
import RichTable from './components/richTable/RichTable.jsx'
import Tooltip from './Tooltip.js';

function MarketDataStatus(props) {

    const [historicalData, setHistoricalData] = useState([]);
    const [quoteData, setQuoteData] = useState({});

    useEffect(() => {
        (async function () {
            let data = await (await fetch(`/api/quoteHistoryAudit`)).json();
            setHistoricalData(data);
        })();
    }, []);

    function getHistoricalData() {
        return historicalData.map(data => {
            return {
                symbol: data.symbol,
                count: data.count
            }
        });
    }

    async function getQuoteData(symbol) {
        setQuoteData({})
        const url = "/api/quote?symbols=" + symbol;
        const d = await (await fetch(url)).json();
        setQuoteData(d);
    }

    function symbolFormatter(cell, row) {
        return (
            <>
                <Tooltip textClass="tooltip-link" onOpen={() => getQuoteData(row.symbol)} text={cell} json={quoteData} position="right center" width="500px" />
            </>
        )
    }

    return (
        <div>
            <RichTable data={getHistoricalData()} className="table-striped table-hover table-condensed" mappers={[
                { title: 'Symbol', field: 'symbol', formatter: symbolFormatter },
                { title: 'Historical Bars', field: 'count' },
            ]} />
        </div>
    );
}

export default MarketDataStatus;