import React, { useState } from 'react';

import { slide as Menu } from 'react-burger-menu'
import { Link } from 'react-router-dom'
import twitterLogo from './images/twitter_logo.jpg';

function Navigation(props) {

    const [isOpen, setOpen] = useState(false)

    const handleIsOpen = () => {
        setOpen(!isOpen)
    }

    const closeSideBar = () => {
        setOpen(false)
    }

    const isLoggedIn = props.userId !== 2

    return (
        <Menu isOpen={isOpen}
            onOpen={handleIsOpen}
            onClose={handleIsOpen}
            right width={'300px'}
            outerContainerId={props.outerContainerId}
            noOverlay={false}
            pageWrapId={props.pageWrapId}>
            <div>

                <div className="nav-section">
                    <Link onClick={closeSideBar} id="help" className="menu-item nav-menu-item" to={"/help"}>Help</Link>
                    <Link onClick={closeSideBar} id="plans" className="menu-item nav-menu-item" to={"/subscriptions"}>Change Plan</Link>
                </div>
                <div className="nav-section">
                    <div className="nav-header">Your Plan: {props.planName}</div>
                    <div className="nav-links">
                        <Link onClick={closeSideBar} id="newtrades" className="menu-item nav-menu-item" to={"/overview"}>Overview</Link>
                        <Link onClick={closeSideBar} id="newtrades" className="menu-item nav-menu-item" to={"/newTrades"}>Today's Trades</Link>
                        <Link onClick={closeSideBar} id="recentupdates" className="menu-item nav-menu-item" to={"/recentUpdates"}>Trade History</Link>
                        <Link onClick={closeSideBar} id="positions" className="menu-item nav-menu-item" to={"/plan"}>Positions</Link>
                        <Link onClick={closeSideBar} id="activetrades" className="menu-item nav-menu-item" to={"/activeTrades"}>Active Trades</Link>
                        <Link onClick={closeSideBar} id="completedTrades" className="menu-item nav-menu-item" to={"/completedTrades"}>Closed Trades</Link>
                    </div>
                </div>

                <div className="nav-section">
                    <div className="nav-header">Performance</div>
                    <div class-name="nav-links">
                        <Link onClick={closeSideBar} id="detailedPerformance" className="menu-item nav-menu-item" to={"/detailedPerformance"}>Detailed Performance</Link>
                        <Link onClick={closeSideBar} id="lastBacktest" className="menu-item nav-menu-item" to={"/lastBacktest"}>Most Recent Backtest</Link>
                        <Link onClick={closeSideBar} id="forecast" className="menu-item nav-menu-item" to={"/forecast"}>Forecaster</Link>
                        <Link onClick={closeSideBar} id="runtest" className="menu-item nav-menu-item" to={"/runbacktest"}>Run Backtest</Link>
                    </div>
                </div>

                {props.role >= 1 ?
                    <div className="nav-section">
                        <div className="nav-header">Plan</div>
                        <div className="nav-links">
                            <Link onClick={closeSideBar} id="replay" className="menu-item nav-menu-item" to={"/replay"}>Replay</Link>
                            <Link onClick={closeSideBar} id="stresstest" className="menu-item nav-menu-item" to={"/stresstest"}>Monte Carlo Simulation</Link>
                            <Link onClick={closeSideBar} id="correlations" className="menu-item nav-menu-item" to={"/correlations"}>Plan Builder</Link>
                            <Link onClick={closeSideBar} id="algoStats" className="menu-item nav-menu-item" to={"/algoStats"}>Algo Performance</Link>
                            <Link onClick={closeSideBar} id="managealgos" className="menu-item nav-menu-item" to={"/managealgos"}>Update Plan Algos</Link>
                            <Link onClick={closeSideBar} id="manageplans" className="menu-item nav-menu-item" to={"/manageplans"}>Manage Plans</Link>
                        </div>
                    </div>
                    : null
                }

                {props.role >= 2
                    ? <div className="nav-section">
                        <div className="nav-header">Admin</div>
                        <div className="nav-links">
                            <Link onClick={closeSideBar} id="manageSystem" className="menu-item nav-menu-item" to={"/manageSystem"}>Manage System</Link>
                            <Link onClick={closeSideBar} id="strategies" className="menu-item nav-menu-item" to={"/strategies"}>Alerts</Link>
                            {props.role === 3 ? <><Link id="signals" onClick={() => { closeSideBar() }} className="menu-item nav-menu-item" to={"/signals"}>Signal History</Link></> : null}
                            <Link onClick={closeSideBar} id="adminStats" className="menu-item nav-menu-item" to={"/admin/stats"}>App Stats</Link>
                        </div>
                    </div>
                    : null
                }

                <div className="nav-section">
                    <div className="nav-header">Account</div>
                    <div className="nav-links">
                        {
                            isLoggedIn ? <a onClick={props.onLogout} href='/logout' id="logout" className="menu-item nav-menu-item">Logout</a>
                                : <a onClick={props.onLogout} id="runtest" className="menu-item nav-menu-item" href='/login'>Login</a>
                        }
                    </div>
                </div>
                <div className="nav-section">
                    <div className="nav-links">
                        <Link onClick={() => { closeSideBar() }} id="runtest" className="menu-item nav-menu-item" target={"_blank"} to={"https://discord.com/channels/1227965055488757761/1227971717712838747"}>Discord Server</Link>
                        <Link target="_blank" className="bg-transparent" to={"https://twitter.com/AlgoStockTrades"}><img alt="View our Twitter" className="social-icon" src={twitterLogo} /></Link>
                    </div>
                </div>
            </div>
        </Menu>
    )
}

export default Navigation;