import React from 'react';
import './index.css'
import UserAgreement from './legal/user'
import forceLogout from './helpers/forceLogout'

function UserAgreementInterstitial(props) {
console.log(`UserAgreementInterstitial: ${props.userId}`)
    async function handleSubmit() {
        console.log("handleSubmit");
        const url = "/api/userAgreement?userId=" + props.userId;

        const response = await fetch(url, { method: "POST" })
        if (!response.ok) {
            console.error(`Failed to submit agreement - HTTP ${response.status}`)
            forceLogout()
            return
        }


        if (props.onAgreementSigned)
            props.onAgreementSigned();
    }

    function renderCheckbox() {

        return (
            <>
                <div style={{ background: "#BFBFBF", padding: "20px", width: "500px" }}>
                    <input type='checkbox' id='agree' onClick={() => handleSubmit()} />&nbsp;<label for='agree'>I agree to the user agreement.</label>
                </div>
            </>
        )
    }

    return (
        <div>
            <br></br>
            <br></br>
            {renderCheckbox()}
            <br></br>
            <br></br>
            <UserAgreement />
            <br></br>
            {renderCheckbox()}
        </div >
    );
}

export default UserAgreementInterstitial;