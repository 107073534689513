import './stats.css'
import { useState, useEffect } from 'react'
import { moneyFormatterNoCents, percentFormatter } from '../TableHelpers'

function Stat({ stat }) {

  const display = (() => {
    switch (stat.type) {
      case 'int':
        return stat.value
      case 'money':
        return moneyFormatterNoCents(stat.value)
      case 'percent':
        return percentFormatter(stat.value)
      default:
        return stat.value
    }
  })()

  return (
    <tr>
      <td className='stat-cell stat-title'>{stat.title}</td>
      <td className='stat-cell stat-value'>{display}</td>
    </tr>
  )
}

export default function Stats() {

  const [stats, setStats] = useState([])
  const [error, setError] = useState(null)

  useEffect(() => {
    async function fetchData() {
      const response = await fetch('/api/stats')

      if (!response.ok) {
        setError('Sorry, you don\'t have access to this page')
        return
      }

      const data = await response.json()

      console.log(data)

      setStats(data)
    }
    fetchData()
  }, [])

  return(<>
    <h2>Site Statistics</h2>
    {
      stats.length > 0 &&
        <table className='stats-table'>
          <tbody>
          {
            stats.map((stat, index) => {
              return <Stat key={index} stat={stat} />
            })
          }
          </tbody>
        </table>
    }
    {error && <p>{error}</p>}
  </>)
}