import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import PositionsTable from './PositionTable.js';
import PositionsChart from './PositionsChart.js';

function Plan(props) {

    const [data, setData] = useState([]);
    const [previousPositions, setPreviousPositions] = useState([]);

    useEffect(() => {
        (function () {

            fetch(`/api/positions?planId=` + props.planId)
                .then(response => response.json())
                .then(text => {

                    setData(text)
                });

            fetch(`/api/previousPositions?planId=` + props.planId)
                .then(response => response.json())
                .then(text => {

                    setPreviousPositions(text)
                });
        })();
    }, [props.planId]);

    async function reloadQuotes() {
        const pos = await (await fetch(`/api/positions?planId=` + props.planId)).json();
        setData(pos);

        const prev = await (await fetch(`/api/previousPositions?planId=` + props.planId)).json();
        setPreviousPositions(prev);
    }

    function handleRefreshClicked() {
        reloadQuotes();
    }

    return (
        <>
            <div>
                <center>
                    Want to chat with other traders and get insights on what the trading plans are doing?
                    <br></br><Link className="bg-transparent" to={"https://discord.gg/fWtCEQjVWe"} >Join our Discord server.</Link>
                </center>

                <PositionsTable previousPositions={previousPositions} planId={props.planId} accountValue={props.accountValue} positions={data} mobileView={props.mobileView} />

                <center>
                    <Link className="bg-transparent" onClick={handleRefreshClicked}>Refresh</Link>
                </center>

                <PositionsChart planId={props.planId} positions={data} />
            </div>
        </>
    );
}

export default Plan;