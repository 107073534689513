import React from 'react';
import tableHelpers from './TableHelpers.js'
import backtestResultsHelpers from './BacktestResultsHelpers.js'
import RichTable from './components/richTable/RichTable.jsx'

function RecentReturns(props) {

    function getReturns() {

        let currentYear = new Date().getUTCFullYear();
        let currentMonth = new Date().getMonth();
        let oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
        let twoWeeksAgo = new Date();
        twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
        let oneMonthAgo = new Date();
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        let twoMonthsAgo = new Date();
        twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);
        let threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
        let sixMonthsAgo = new Date();
        sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
        let oneYearAgo = new Date();
        oneYearAgo.setUTCFullYear(oneYearAgo.getUTCFullYear() - 1);


        let result = [
            {
                name: 'Current Drawdown',
                result: backtestResultsHelpers.getCurrentDrawdown(props.backtestResults, false),
                spyResult: backtestResultsHelpers.getCurrentDrawdown(props.backtestResults, true),
            },
            {
                name: 'YTD',
                result: backtestResultsHelpers.getReturnSince(props.backtestResults, new Date(currentYear, 0, 1), false),
                spyResult: backtestResultsHelpers.getReturnSince(props.backtestResults, new Date(currentYear, 0, 1), true),
            },
            {
                name: 'MTD',
                result: backtestResultsHelpers.getReturnSince(props.backtestResults, new Date(currentYear, currentMonth, 1), false),
                spyResult: backtestResultsHelpers.getReturnSince(props.backtestResults, new Date(currentYear, currentMonth, 1), true),
            },
            {
                name: '1 Week',
                result: backtestResultsHelpers.getReturnSince(props.backtestResults, oneWeekAgo, false),
                spyResult: backtestResultsHelpers.getReturnSince(props.backtestResults, oneWeekAgo, true),
            },
            {
                name: '2 Weeks',
                result: backtestResultsHelpers.getReturnSince(props.backtestResults, twoWeeksAgo, false),
                spyResult: backtestResultsHelpers.getReturnSince(props.backtestResults, twoWeeksAgo, true),
            },
            {
                name: '1 Month',
                result: backtestResultsHelpers.getReturnSince(props.backtestResults, oneMonthAgo, false),
                spyResult: backtestResultsHelpers.getReturnSince(props.backtestResults, oneMonthAgo, true),
            },
            {
                name: '2 Months',
                result: backtestResultsHelpers.getReturnSince(props.backtestResults, twoMonthsAgo, false),
                spyResult: backtestResultsHelpers.getReturnSince(props.backtestResults, twoMonthsAgo, true),
            },
            {
                name: '3 Months',
                result: backtestResultsHelpers.getReturnSince(props.backtestResults, threeMonthsAgo, false),
                spyResult: backtestResultsHelpers.getReturnSince(props.backtestResults, threeMonthsAgo, true),
            },
            {
                name: '6 Months',
                result: backtestResultsHelpers.getReturnSince(props.backtestResults, sixMonthsAgo, false),
                spyResult: backtestResultsHelpers.getReturnSince(props.backtestResults, sixMonthsAgo, true),
            },
            {
                name: '1 Year',
                result: backtestResultsHelpers.getReturnSince(props.backtestResults, oneYearAgo, false),
                spyResult: backtestResultsHelpers.getReturnSince(props.backtestResults, oneYearAgo, true),
            },
        ];

        console.log("Start Date", props.accountStartDate);
        if (props.accountStartDate) {
            result.push({
                name: 'Account Start Date',
                result: backtestResultsHelpers.getReturnSince(props.backtestResults, new Date(props.accountStartDate), false),
                spyResult: backtestResultsHelpers.getReturnSince(props.backtestResults, new Date(props.accountStartDate), true),
            });
        }

        result.forEach(r => {
            let startAmount = props.accountValue / (r.result / 100 + 1);
            let profit = props.accountValue - startAmount;
            let spyProfit = (startAmount * (1 + r.spyResult / 100) - startAmount);
            r.dollarDiff = profit - spyProfit;
            r.dollarProfit = profit;
        })

        console.log(result);
        return result;
    }

    const returnMapper = [
        { title: 'Category', field: 'name' },
        { title: 'S&P 500', field: 'spyResult', formatter: tableHelpers.percentFormatter },
        { title: 'Plan', field: 'result', formatter: tableHelpers.percentFormatter },
        { title: 'Plan Amount', field: 'dollarProfit', formatter: tableHelpers.moneyFormatterNoCents },
    ]

    return (
        <>
            <div>
                <RichTable data={getReturns()} mappers={returnMapper} className="table-striped table-hover table-condensed" />
            </div>
        </>
    );
}

export default RecentReturns; 