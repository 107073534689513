import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";

function AlertParameterFinder(props) {

    const [entries, setEntries] = useState(null)
    const [chartValue, setChartValue] = useState(null)
    const [bars, setBars] = useState(14);
    const [count, setCount] = useState(100);

    useEffect(() => {
        (async function () {

            console.log("useEffect", props.config);


        })();
    }, [props.config]);

    async function findBestEntries() {
        console.log("Finding best entries", props.config);
        let url = "/api/strategyAlertParameterEvaluator?alertId=" + props.alertId
        if (props.startDate) url += "&startDate=" + props.startDate.toLocaleDateString();
        if (props.endDate) url += "&endDate=" + props.endDate.toLocaleDateString();
        url += "&bars=" + bars
        url += "&count=" + count;

        const r = await (await fetch(url, {
            method: 'POST',
            body: JSON.stringify(props.config)
        })).json();

        setEntries(r);

        console.log(r);
    }

    function getChartData() {

        if (entries && entries.length && entries[0].startData[chartValue]) {
            let chartData = [];
            let rank = 0;
            entries.forEach(entry => {
                chartData.push([++rank, entry.startData[chartValue]])
            });

            if (chartData.length)
                chartData.unshift(['Rank', 'Value']);

            return chartData;
        } else {
            return null;
        }
    }

    let chartData = getChartData()

    return (
        <div>
            Days: <input type="text" value={bars} onChange={(e) => setBars(e.target.value)} />
            Count: <input type="text" value={count} onChange={(e) => setCount(e.target.value)} />
            <button onClick={() => findBestEntries()}>Find Best Entries</button>
            <br></br>
            Chart: <input type="text" onChange={(e) => setChartValue(e.target.value)} />
            {entries && chartData ?
                <Chart
                    chartType="ColumnChart"
                    data={chartData}
                    height={"500px"}
                    width={"100%"}
                    chartPackages={["corechart", "controls"]}
                />
                :
                null}

        </div >
    );
}

export default AlertParameterFinder;